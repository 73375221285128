import classnames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { useElementMeasure } from '../../hooks/use-element-measure'
import { Button, ButtonPadding, ButtonProps, ButtonRoundingType, ButtonSize, ButtonSizeType } from '../button'
import { LabelWithDelayedSpinner } from '../label-with-delayed-spinner'
import { DateRangeBaseOption, DateRangeSelect } from './daterange-select'
import { TimeframeBaseOption, TimeframeSelect } from './timeframe-select'

interface Props<TimeFrame, DateRange> {
  isPremium?: boolean
  chartIndex?: number
  compactViewMaxBreakpointPx?: number
  timeFrameGroups: Array<{ label: string; timeframes: TimeFrame[] }>
  dateRangeGroups: Array<{ label?: string; dateRanges: DateRange[] }>
  selectedTimeframe: string
  selectedDateRange: string | null
  onTimeframeSelect: (value: TimeFrame) => void
  onDateRangeSelect: (value: DateRange) => void
  onCustomDateRangeClick?: () => void
  favoriteTimeframes: string[]
  onFavoriteTimeframeToggle: (value: TimeFrame) => void
  size?: ButtonSizeType
  loadingTimeframe?: string
  rounding?: ButtonRoundingType
  isDateRangeAvailable?: boolean
  containerElement?: HTMLElement
  className?: string
  isFavoritesEnabled?: boolean
  isScrollIntoViewEnabled?: boolean
  isTimeframeSelectEnabled?: boolean
  isCompactOnly?: boolean
  theme?: ButtonProps['theme']
}

function getTimeframeChipElementId({ timeframe, chartIndex }: { timeframe: string; chartIndex: number }) {
  return `${chartIndex}-${timeframe}`
}

function getButtonPadding(size: ButtonSize): keyof typeof ButtonPadding {
  switch (size) {
    case ButtonSize.xxsmall:
    case ButtonSize.xsmall:
    case ButtonSize.small:
    case ButtonSize.regular:
      return 'small'
    case ButtonSize.medium:
      return 'regular'
    case ButtonSize.large:
    case ButtonSize.xlarge:
      return 'medium'
    case ButtonSize.none:
      return 'none'
  }
}

export function TimeframeBar<TimeFrame extends TimeframeBaseOption, DateRange extends DateRangeBaseOption>({
  isPremium = FinvizSettings.hasUserPremium,
  chartIndex = 0,
  compactViewMaxBreakpointPx = 400,
  timeFrameGroups,
  dateRangeGroups,
  selectedTimeframe,
  selectedDateRange,
  favoriteTimeframes,
  loadingTimeframe,
  onTimeframeSelect,
  onDateRangeSelect,
  onCustomDateRangeClick,
  onFavoriteTimeframeToggle,
  size,
  rounding,
  isDateRangeAvailable = true,
  containerElement,
  className,
  isFavoritesEnabled = true,
  isScrollIntoViewEnabled = true,
  isTimeframeSelectEnabled,
  isCompactOnly = false,
  theme,
}: Props<TimeFrame, DateRange>) {
  const [scrollWrapperElement, setScrollWrapperElement] = React.useState<HTMLDivElement | null>(null)
  const [timeframeButtonsScrollLocation, setTimeframeButtonsScrollLocation] = React.useState({
    isOnLeftEdge: true,
    isOnRightEdge: true,
  })
  const { setElementRef, elementWidth } = useElementMeasure(containerElement)
  const flatTimeframes = React.useMemo(() => timeFrameGroups.flatMap(({ timeframes }) => timeframes), [timeFrameGroups])

  const timeframesInExpandedPanel = React.useMemo(
    () =>
      isFavoritesEnabled
        ? flatTimeframes.filter(({ value }) => value === selectedTimeframe || favoriteTimeframes.includes(value))
        : flatTimeframes,
    [flatTimeframes, selectedTimeframe, favoriteTimeframes, isFavoritesEnabled]
  )

  const isCompactView =
    isCompactOnly || elementWidth < compactViewMaxBreakpointPx || timeframesInExpandedPanel.length <= 1
  const checkTimeframeWrapperScrollPosition = React.useCallback(() => {
    if (scrollWrapperElement) {
      const isOnLeftEdge = scrollWrapperElement.scrollLeft === 0
      const isOnRightEdge =
        Math.ceil(scrollWrapperElement.offsetWidth + scrollWrapperElement.scrollLeft) >=
        scrollWrapperElement.scrollWidth

      if (
        timeframeButtonsScrollLocation.isOnLeftEdge !== isOnLeftEdge ||
        timeframeButtonsScrollLocation.isOnRightEdge !== isOnRightEdge
      ) {
        setTimeframeButtonsScrollLocation({ isOnLeftEdge, isOnRightEdge })
      }
    }
  }, [timeframeButtonsScrollLocation, scrollWrapperElement])

  React.useEffect(() => {
    checkTimeframeWrapperScrollPosition()
  }, [checkTimeframeWrapperScrollPosition, scrollWrapperElement, elementWidth])

  React.useEffect(() => {
    if (!isCompactView && isScrollIntoViewEnabled) {
      const timeframeElement = document.getElementById(
        getTimeframeChipElementId({ chartIndex, timeframe: selectedTimeframe })
      )

      if (timeframeElement && scrollWrapperElement) {
        const leftOffset =
          timeframeElement.offsetLeft + timeframeElement.clientWidth / 2 - scrollWrapperElement.clientWidth / 2

        scrollWrapperElement.scrollTo(Math.max(0, Math.min(leftOffset, scrollWrapperElement.scrollWidth)), 0)
      }
    }
  }, [isCompactView, chartIndex, selectedTimeframe, elementWidth, isScrollIntoViewEnabled, scrollWrapperElement])

  return (
    <div
      className={classnames(
        'relative -left-0.5 -m-0.5 -ml-0 flex flex-grow items-center overflow-hidden p-0.5',
        {
          'space-x-2': !className,
        },
        className
      )}
      ref={setElementRef}
    >
      {(isTimeframeSelectEnabled ?? isFavoritesEnabled) && (
        <div className={classnames({ 'flex-none': !isCompactOnly, 'flex-grow': isCompactOnly })}>
          <TimeframeSelect
            theme={theme}
            chartIndex={chartIndex}
            size={size}
            rounding={rounding}
            selectedTimeframe={selectedTimeframe}
            timeFrameGroups={timeFrameGroups}
            favoriteTimeframes={favoriteTimeframes}
            isCompactView={isCompactView}
            isCompactOnly={isCompactOnly}
            isLoading={!!loadingTimeframe && isCompactView}
            onFavoriteTimeframeToggle={onFavoriteTimeframeToggle}
            onTimeframeSelect={onTimeframeSelect}
            isFavoritesEnabled={isFavoritesEnabled}
          />
        </div>
      )}
      {!isCompactView && (
        <div data-testid="chart-favorite-timeframe" className="relative -left-0.5 !-mr-1 overflow-hidden p-0.5">
          <div
            className={classnames(
              'pointer-events-none absolute left-0 top-0 z-10 h-full w-2 bg-gradient-to-r from-white to-transparent dark:from-gray-800 dark:to-transparent',
              { 'opacity-0': timeframeButtonsScrollLocation.isOnLeftEdge }
            )}
          />
          <div
            ref={setScrollWrapperElement}
            className="time-frames-scrollable -m-0.5 flex flex-1 space-x-px overflow-y-auto p-0.5"
            onScroll={checkTimeframeWrapperScrollPosition}
            onWheel={(event) => {
              /*
               * Vertical scrolling doesn't scroll horizontally by default (it's possible when holding shift)
               * When using touchpad we may get values in both delatX and deltaY, so if someone's scrolling horizontally we don't want to do anything
               * but if they are scrolling vertically (deltaY > deltaX) we want to scroll horizontally the value they would nativally scroll in vertical direction
               * */
              const absDx = Math.abs(event.deltaX)
              const absDy = Math.abs(event.deltaY)
              if (absDy > absDx) {
                event.currentTarget.scrollLeft += event.deltaY
              }
            }}
          >
            {timeframesInExpandedPanel.map((timeframe) => {
              let linkProps = {}
              if (timeframe.href) {
                linkProps = {
                  as: Link,
                  to: timeframe.href,
                  reloadDocument: timeframe.withReload ?? !isPremium,
                  onClick: () => onTimeframeSelect(timeframe),
                }
              } else {
                linkProps = {
                  onClick: (ev: React.MouseEvent) => {
                    ev.preventDefault()
                    onTimeframeSelect(timeframe)
                  },
                }
              }
              return (
                <Button
                  {...linkProps}
                  key={timeframe.value}
                  id={getTimeframeChipElementId({ chartIndex, timeframe: timeframe.value })}
                  className="relative focus-visible:z-10"
                  data-testid={`chart-${chartIndex}-favorite-timeframe-${timeframe.value}`}
                  theme="chipTransparent"
                  size={size}
                  padding={getButtonPadding(ButtonSize[size ?? 'regular'])}
                  active={timeframe.value === selectedTimeframe}
                  rounding={rounding}
                >
                  <LabelWithDelayedSpinner
                    label={timeframe.labelShort}
                    isLoading={loadingTimeframe === timeframe.value}
                  />
                </Button>
              )
            })}
          </div>
          <div
            className={classnames(
              'pointer-events-none absolute right-0 top-0 z-10 h-full w-2 bg-gradient-to-r from-transparent to-white dark:from-transparent dark:to-gray-800',
              { 'opacity-0': timeframeButtonsScrollLocation.isOnRightEdge }
            )}
          />
        </div>
      )}
      {isDateRangeAvailable && (
        <div className="flex-none">
          <DateRangeSelect
            chartIndex={chartIndex}
            size={size}
            dateRangeGroups={dateRangeGroups}
            selectedDateRange={selectedDateRange}
            onDateRangeSelect={onDateRangeSelect}
            rounding={rounding}
            onCustomDateRangeClick={onCustomDateRangeClick}
          />
        </div>
      )}
    </div>
  )
}
