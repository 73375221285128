import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { isIpad, isMobile } from '../../../app/shared/isMobile'
import { useScreenSize } from '../../hooks/use-screen-size'
import { useVisualViewport } from '../../hooks/useVisualViewport'
import { ChildPosition, blurWithoutScroll, getFlexAlignClasses } from '../../util'
import { DialogBox, DialogBoxProps } from './dialog-box'
import { DialogDragContext, DialogStateReturn, ZIndexContext, useDrag, useZIndex } from './hooks'

export enum DialogType {
  modal,
  drawer,
}

export interface DialogProps extends DialogBoxProps {
  /**
   * The returned object from `useDialogState`
   */
  state: DialogStateReturn

  /**
   * When true, all outside elements won’t be interactable
   *
   * @default true
   */
  modal?: boolean

  /**
   * Sets if the dialog can be dragged across the screen
   *
   * @default false
   */
  isDraggable?: boolean

  /**
   * Type of dialog
   *
   * @default modal
   */
  type?: keyof typeof DialogType

  /**
   * Dialog position on the screen
   *
   * @default center
   */
  position?: keyof typeof ChildPosition

  /**
   * Hide the dialog when user presses ESC
   *
   * @default true
   */
  hideOnEsc?: Ariakit.DialogProps['hideOnEscape']

  /**
   * Show backdrop on the dialog. Provide your own element if you want to add background or animation
   *
   */
  backdrop?: false | JSX.Element

  /**
   * Callback which is called when the dialog opens and all animations complete
   */
  onFullyOpen?: () => void

  /**
   * Callback which is called when the dialog closes and all animations complete
   */
  onFullyClosed?: () => void

  /**
   * Callback before the dialog starts hiding. Event can be prevented which keeps the dialog open
   * This callback isn’t called on backdrop click. Use `backdrop={<div onMouseDown={…} />}` to react on backdrop clicks
   */
  onClose?: (event: Event) => void
}

export function Dialog({
  state,
  modal = true,
  isDraggable = false,
  type = 'modal',
  position = 'center',
  hideOnEsc = true,
  backdrop,
  onFullyOpen,
  onFullyClosed,
  onClose,
  children,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  ...props
}: React.PropsWithChildren<DialogProps>) {
  const zIndex = useZIndex('z-dialog')
  const dialogDrag = useDrag(isDraggable)
  const isOpen = state.useState('open')
  const [isFullyOpen, setIsFullyOpen] = React.useState(false)
  const { viewport } = useVisualViewport({ enabled: isOpen, onScroll: false })
  const screenSize = useScreenSize()

  const dialogStyle = React.useMemo(() => {
    if (isMobile() && !isIpad() && viewport.scale !== 1) {
      return {
        left: viewport.left,
        top: viewport.top,
        width: viewport.unscaledWidth,
        height: viewport.unscaledHeight,
        minWidth: screenSize.width,
        minHeight: screenSize.width,
        willChange: 'auto',
      }
    }
    return
  }, [screenSize, viewport])

  return (
    <Ariakit.Dialog
      // Do not keep the dialog mounted when the popover is closed
      unmountOnHide
      store={state}
      portal
      modal={modal}
      preventBodyScroll={modal}
      hideOnEscape={hideOnEsc}
      // We are using the backdrop to hide the dialog. Passing `false` to backdrop makes the dialog persistent (eg. notes)
      hideOnInteractOutside={false}
      backdrop={backdrop ?? <div onMouseDown={() => state.hide()} />}
      autoFocusOnHide={blurWithoutScroll}
      aria-label={ariaLabel}
      // E2E tests don’t wait for transitions to complete and it could lead to bugs with other popover components (ResizeObserver loop exceeded)
      // or visual diffs due a screenshot being captured while the dialog still in transition
      // If you encounter this problem, just do `cy.get('[data-fully-open=true]').should('exist')` before continuing to wait for the dialog
      data-fully-open={isFullyOpen}
      data-testid={dataTestId}
      style={dialogStyle}
      className={classnames(
        'group/dialog pointer-events-none fixed bottom-0 left-0 right-0 top-0 flex max-w-full outline-none transition duration-[250ms] ipad-fullscreen:pt-5',
        zIndex,
        DialogType[type] === DialogType.modal && getFlexAlignClasses(ChildPosition[position]),
        {
          'p-2 opacity-0 data-[enter]:scale-100 data-[enter]:opacity-100 motion-safe:scale-95 sm:p-5':
            DialogType[type] === DialogType.modal,
          'justify-end data-[enter]:translate-x-0 motion-safe:translate-x-full': DialogType[type] === DialogType.drawer,
        }
      )}
      onTransitionEnd={(ev) => {
        if (ev.target === ev.currentTarget && isOpen) {
          setIsFullyOpen(true)
          onFullyOpen?.()
        }
      }}
      onClose={(ev) => {
        onClose?.(ev)
        // prevented means the dialog didn’t close
        if (!ev.defaultPrevented) setIsFullyOpen(false)
      }}
    >
      <DialogDragContext.Provider value={dialogDrag}>
        <ZIndexContext.Provider value="z-dialog">
          <DialogBox
            {...props}
            className={classnames(props.className, 'pointer-events-auto')}
            onUnmount={() => {
              onFullyClosed?.()
              dialogDrag.resetBoxPosition()
            }}
          >
            {children}
          </DialogBox>
        </ZIndexContext.Provider>
      </DialogDragContext.Provider>
    </Ariakit.Dialog>
  )
}

export const DialogDismiss = Ariakit.DialogDismiss
