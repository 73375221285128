import { SortByShape, SortableColumn, isSortByShape, isSortableColumnShape } from '../../hooks/use-sort-data'
import { SortDirection } from '../../types'

/**
 * Default sort getter which either gets column matching by `defaultSortId` or returns the first column
 */

export function getDefaultSort<ColumnType extends SortableColumn<any, any>, DefaultSort>(
  columns: ColumnType[],
  defaultSort?: DefaultSort
): SortByShape<DefaultSort extends null ? ColumnType | null : ColumnType> {
  if (defaultSort === null) return { column: null as unknown as ColumnType, direction: SortDirection.Ascending }

  if (isSortByShape(defaultSort)) {
    if (isSortableColumnShape(defaultSort.column)) {
      return defaultSort as unknown as SortByShape<ColumnType>
    }

    return { ...defaultSort, column: columns.find((column) => column.id === defaultSort.column) ?? columns[0] }
  }

  const foundColumn = columns.find(
    (column) => column.id === (isSortableColumnShape(defaultSort) ? defaultSort.id : defaultSort)
  )

  return { column: foundColumn ?? columns[0], direction: SortDirection.Ascending }
}
/**
 * Get default sorting from url when persisting sort
 */

export function getDefaultSortFromUrl<ColumnType extends SortableColumn<any, any>, OrderType extends string | null>(
  columns: ColumnType[],
  order?: OrderType,
  idKey: keyof ColumnType = 'id'
): SortByShape<OrderType extends null ? ColumnType | null : ColumnType> {
  if (order === null) return { column: null as unknown as ColumnType, direction: SortDirection.Ascending }

  const isDesc = order?.startsWith('-')
  const queryParam = isDesc ? order?.substring(1) : order
  const column = columns.find((column) => column[idKey] === queryParam)

  if (column) {
    return { column, direction: isDesc ? SortDirection.Descending : SortDirection.Ascending }
  } else {
    return { column: columns[0], direction: SortDirection.Ascending }
  }
}

export function saveSortToUrl<ColumnType extends SortableColumn<any, any>>(
  prev: URLSearchParams,
  urlPersistKey: string,
  newSort: ColumnType | null,
  idKey: keyof ColumnType = 'id',
  defaultSort: unknown = null
) {
  const newParams = new URLSearchParams(prev)
  const previousOrder = newParams.get(urlPersistKey) ?? defaultSort
  const isDescOrder = previousOrder?.toString().startsWith('-')

  if (newSort === null) {
    newParams.delete(urlPersistKey)
  } else {
    newParams.set(urlPersistKey, `${!isDescOrder && previousOrder === newSort[idKey] ? '-' : ''}${newSort[idKey]}`)
  }

  return newParams
}
