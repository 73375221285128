import classnames from 'classnames'
import * as React from 'react'

import { Button, ButtonRounding, ButtonRoundingType } from '../button'

export enum GroupTheme {
  light,
  dark,
}

export interface ButtonGroupProps {
  children: React.ReactNode

  /**
   * Border radius of entire group wrapper
   *
   * @default "regular"
   */
  rounding?: ButtonRoundingType

  className?: string

  /**
   * If a pipe divider between buttons should be rendered
   *
   * @default true
   */
  hasDivider?: boolean

  /**
   * If group wrapper has a border
   *
   * @default true
   */
  hasBorder?: boolean

  /**
   * Group color theme
   *
   * @default "light"
   */
  theme?: keyof typeof GroupTheme

  /**
   * Custom button component which extends Button
   *
   * @default undefined
   */
  childComponent?: string | React.JSXElementConstructor<any>
}

function getGroupTheme(theme: GroupTheme) {
  switch (theme) {
    case GroupTheme.dark:
      return {
        wrapper: 'bg-gray-800 border-gray-600',
        divider: 'bg-gray-600',
      }
    default:
      return {
        wrapper: 'bg-white dark:bg-gray-800 border-gray-100 dark:border-gray-600',
        divider: 'bg-gray-100 dark:bg-gray-600',
      }
  }
}

export function ButtonGroup({
  className,
  children,
  theme = 'light',
  hasDivider = true,
  hasBorder = true,
  rounding = 'regular',
  childComponent,
}: ButtonGroupProps) {
  const buttonRounding = ButtonRounding[rounding]
  const groupTheme = React.useMemo(() => getGroupTheme(GroupTheme[theme]), [theme])
  const numberOfChildren = React.Children.count(children)

  return (
    <div
      role="group"
      className={classnames(
        'inline-flex items-center overflow-hidden',
        { border: hasBorder },
        groupTheme.wrapper,
        buttonRounding,
        className
      )}
    >
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child) || ![Button, childComponent].filter(Boolean).includes(child.type)) {
          return child
        }

        const isButtonRounded = !hasBorder && rounding !== 'none'
        const isSideButton = index === 0 || index === numberOfChildren - 1
        const givenRounding = isButtonRounded && isSideButton ? rounding : 'none'
        const givenTheme = GroupTheme[theme] === GroupTheme.dark ? 'menuItemDark' : 'menuItem'

        return (
          <React.Fragment key={index}>
            {hasDivider && index > 0 && <div className={classnames('h-5 w-px', groupTheme.divider)} />}
            {React.cloneElement(child as React.ReactElement<React.ComponentProps<typeof Button>>, {
              rounding: child.props.rounding ?? givenRounding,
              theme: child.props.theme ?? givenTheme,
              className: classnames(child.props.className, 'hover:relative hover:z-10', {
                relative: child.props.active,
                'rounded-r-none': !child.props.rounding && isButtonRounded && index === 0,
                'rounded-l-none': !child.props.rounding && isButtonRounded && index === numberOfChildren - 1,
              }),
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}
