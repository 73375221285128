export enum ScreenerQuery {
  View = 'v',
  Signal = 's',
  Order = 'o',
  Tickers = 't',
  Filters = 'f',
  FilterTab = 'ft',
  MapSubtype = 'st',
}

export enum ScreenerFilter {
  MarketCap = 'cap',
  Index = 'idx',
  Sector = 'sec',
  Industry = 'ind',
  AverageVolume = 'sh_avgvol',
}

export enum ScreenerAddtionalQuery {
  ShowEtf = 'show_etf',
}

export enum ScreenerView {
  Overview = 11,
  Maps = 71,
  Bubbles = 72,
}

export function eventListenerRegister(el: HTMLElement, eventName: string, handler: (...args: any[]) => any) {
  el.addEventListener(eventName, handler)

  return () => {
    el.removeEventListener(eventName, handler)
  }
}

export function textContent(el: HTMLElement) {
  return el.textContent || el.innerText
}

export function ScreenerGetOptionsList(select: HTMLSelectElement) {
  const options = select.getAttribute('data-selected')?.split('|') ?? [],
    result = []
  for (var i = 0, len = options.length; i < len; i++) {
    var option = options[i]
    for (var j = 0; j < select.children.length; j++) {
      var optionEl = select.children[j] as HTMLOptionElement
      if (optionEl.value === option) {
        result.push({
          key: option,
          value: textContent(optionEl),
        })
      }
    }
  }
  return result
}

export function ScreenerRenderCustomModal(select: any) {
  const filter = select.getAttribute('data-filter')
  const customWrapper = document.getElementById('fsm_' + filter) as HTMLElement
  if (customWrapper) {
    select.style.display = 'none'
    customWrapper.style.display = 'block'
    const input = document.getElementById('fsmi_' + filter) as HTMLInputElement
    const options = ScreenerGetOptionsList(select)
    const arr = []
    for (let i = 0, len = options.length; i < len; i++) {
      arr.push(options[i].value)
    }
    input.value = arr.join(', ')
  }
}

const DATE_REGEX = /^[0-9]{2}[-][0-9]{2}[-][0-9]{4}/

export function getIsOptionDateRange(option: string) {
  if (!option.includes('x')) return false

  const [from, to] = option.split('x')
  const [isValidFromDate, isValidToDate] = [from, to].map((value) => {
    if (DATE_REGEX.test(value)) {
      return !Number.isNaN(new Date(value.replace(/-/g, '/')).getTime())
    }
    return false
  })

  return isValidFromDate || isValidToDate
}

export function getInitialDateFromSelectedValues(selectedValues: string[]) {
  const initialDate = selectedValues.find(getIsOptionDateRange)

  if (initialDate) {
    return initialDate.split('x').map((date) => (DATE_REGEX.test(date) ? date.replace(/-/g, '/') : undefined))
  }
}

export function dateRangeToFilterString({ from, to }: { from?: string; to?: string }) {
  return from || to ? `${from ?? ''}x${to ?? ''}`.replace(/\//g, '-') : null
}

/**
 * Creates link for given view & subview and ignores any additional query params
 */
export function getQueryForView(view?: ScreenerView, allowedParams: string[] = [], baseQuery = window.location.search) {
  const query = new URLSearchParams(baseQuery)
  const allowList = [...allowedParams, ...Object.values(ScreenerQuery)]

  /**
   * Seems like `query` is a live property, so we can’t use `query.forEach` to delete,
   * so we need to copy keys before looping.
   * https://stackoverflow.com/questions/60522437
   */
  const keys = Array.from(query.keys())

  keys.forEach((key) => {
    if (!allowList.includes(key)) {
      query.delete(key)
    }
  })

  const [, originalView, settings = 0] = query.get(ScreenerQuery.View)?.match(/(\d{2})(\d)/) ?? []

  if (view ?? originalView) {
    query.set(ScreenerQuery.View, `${view ?? originalView}${settings}`)
  }

  return query
}

/**
 * Parse screener `f` query param to object
 */
export function parseScreenerFilters(baseQuery = window.location.search) {
  const query = new URLSearchParams(baseQuery)
  const filtersString = query.get(ScreenerQuery.Filters)

  if (!filtersString) return {}

  return Object.fromEntries(
    filtersString.split(',').map((f) => {
      const lastUnderscore = f.lastIndexOf('_')
      const key = f.substring(0, lastUnderscore)
      const value = f.substring(lastUnderscore + 1)
      return [key, value.split('|')]
    })
  )
}

/**
 * Stringify screener `f` query param
 */
export function stringifyScreenerFilters(filters: Record<string, string[]>) {
  return Object.entries(filters)
    .filter(([, value]) => String(value).length > 0)
    .map(([key, value]) => `${key}_${value.join('|')}`)
    .join(',')
}

export function rangesToFilter(
  ranges: {
    from: string
    to: string
  }[],
  rangeSuffixes?: string[],
  delimiter = 'x',
  rangeDelimiter = 'to'
) {
  return ranges.reduce((filter, range, index) => {
    const { from, to } = range
    const rangeStr = from || to ? `${from}${rangeDelimiter}${to}${rangeSuffixes?.[index] ?? ''}` : ''

    let currentFilter = filter
    if (index > 0) currentFilter += delimiter
    return currentFilter + rangeStr
  }, '')
}
