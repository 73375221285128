import { EventEmitter } from 'events'
import * as React from 'react'

let emitter: EventEmitter | undefined

/**
 * Get the emitter which is used to sync components across the page, subscibe to
 * changes and attach event which handles changes across windows
 */
export function subscribeToChanges<ValueType>(
  key: string,
  defaultValue: ValueType,
  changeCallback?: (value: React.SetStateAction<ValueType>) => void
) {
  if (!emitter) emitter = new EventEmitter()

  const onChange = (value: React.SetStateAction<ValueType>) => {
    const newValue = value ?? getValue(key, defaultValue)
    setValue(key, newValue, defaultValue, false)
    changeCallback?.(newValue)
  }

  const onStorageChange = (ev: StorageEvent) => {
    if (ev.key === key) {
      try {
        const value = ev.newValue ? JSON.parse(ev.newValue) : null
        onChange(value ?? defaultValue)
      } catch {
        onChange(defaultValue)
      }
    }
  }

  emitter.addListener(key, onChange)
  window.addEventListener('storage', onStorageChange)

  return {
    emitter,
    unsubscribe: () => {
      emitter?.removeListener(key, onChange)
      window.removeEventListener('storage', onStorageChange)
    },
  }
}

/**
 * Get value from local storage or fall back to `defaultValue`
 */
export function getValue<ValueType>(key: string, defaultValue: ValueType): ValueType {
  try {
    const value = localStorage.getItem(key)
    const parsedValue = value ? JSON.parse(value) : null
    return parsedValue ?? defaultValue
  } catch {
    return defaultValue
  }
}

/**
 * Set value to local storage. Optionally emits the change to all other components
 * which are subscribed with the same `key`
 */
export function setValue<ValueType>(
  key: string,
  valueGetter: React.SetStateAction<ValueType>,
  defaultValue: ValueType,
  emit = true
) {
  try {
    const state = getValue(key, defaultValue)
    const valueToStore = valueGetter instanceof Function ? valueGetter(state) : valueGetter

    localStorage.setItem(key, JSON.stringify(valueToStore))
    if (emit) emitter?.emit(key, valueToStore)
  } catch {}
}

/**
 * Removes value from local storage. Optionally emits the change to all other components
 * which are subscribed with the same `key`
 */
export function removeValue(key: string, emit = true) {
  try {
    localStorage.removeItem(key)
    if (emit) emitter?.emit(key, undefined)
  } catch {}
}

/**
 * Helper function to migrate old value type to new one (eg. boolean to object)
 */
export function migrateValue<PrevValue, NewValue>(
  key: string,
  /**
   * The migrate function might get called multiple times on the page, so `oldValue` in the callback
   * can actually be the already migrated value. Make sure to add a condition to only migrate once.
   */
  transformValue: NewValue | ((oldValue: PrevValue | NewValue) => NewValue),
  defaultValue: NewValue
) {
  try {
    const oldValue = getValue<PrevValue | undefined>(key, undefined)
    if (typeof oldValue === 'undefined') return
    const migratedValue = transformValue instanceof Function ? transformValue(oldValue) : transformValue
    setValue(key, migratedValue, defaultValue, false) // don’t emit to other components
  } catch {}
}
