// Keep in sync with charts repository colors.js file in: app/constants/colors.js

module.exports = {
  white: '#fff',
  black: '#000',
  inherit: 'inherit',
  transparent: 'transparent',
  current: 'currentColor',
  finviz: {
    'blue-gray': '#262931',
    blue: '#4fa4f4',
  },
  gray: {
    50: '#f3f3f5',
    100: '#dedfe5',
    200: '#c3c6d0',
    300: '#a3a8b9',
    400: '#868ea5',
    500: '#676f85',
    600: '#4c5263',
    700: '#353945',
    750: '#2c303b',
    800: '#22262f',
    900: '#14161d',
  },
  red: {
    50: '#fef0f0',
    100: '#fcd6d6',
    200: '#fbb4b4',
    300: '#fd8487',
    400: '#fb5057',
    500: '#d91e2b',
    600: '#9f1f2a',
    700: '#691f24',
    800: '#45171a',
    900: '#280e0f',
  },
  orange: {
    50: '#fef2eb',
    100: '#fdd9c5',
    200: '#fab889',
    300: '#ef9323',
    400: '#ca7b14',
    500: '#a0600e',
    600: '#79460a',
    700: '#583006',
    800: '#3d1e03',
    900: '#261101',
  },
  amber: {
    50: '#fef2e7',
    100: '#fedab5',
    200: '#fbba56',
    300: '#de9c1a',
    400: '#bb830f',
    500: '#956600',
    600: '#704b00',
    700: '#4f3400',
    800: '#372200',
    900: '#211300',
  },
  tangerine: {
    50: '#fef1ee',
    100: '#fed8ce',
    200: '#fdb5a0',
    300: '#fc8955',
    400: '#db6e35',
    500: '#b35314',
    600: '#814021',
    700: '#5a2d18',
    800: '#3c1e12',
    900: '#291711',
  },
  yellow: {
    50: '#fdf2df',
    100: '#fcdc86',
    200: '#f3c736',
    300: '#cda30b',
    400: '#b08900',
    500: '#8b6a00',
    600: '#664e21',
    700: '#46371e',
    800: '#2f2516',
    900: '#1b150d',
  },
  brightYellow: {
    50: '#fff699',
    100: '#fff200',
  },
  lime: {
    50: '#ebfad6',
    100: '#caec90',
    200: '#aad547',
    300: '#8db61e',
    400: '#769913',
    500: '#5b7800',
    600: '#435900',
    700: '#2e3e00',
    800: '#1e2a00',
    900: '#111900',
  },
  green: {
    50: '#e7f7e8',
    100: '#beeac1',
    200: '#86d895',
    300: '#34c062',
    400: '#00a449',
    500: '#19803d',
    600: '#265d35',
    700: '#1e4027',
    800: '#162b1b',
    900: '#0d1810',
  },
  emerald: {
    50: '#ddfaee',
    100: '#a2f0d2',
    200: '#4dddaf',
    300: '#25be93',
    400: '#1d9f7b',
    500: '#157e60',
    600: '#035d47',
    700: '#004139',
    800: '#002c22',
    900: '#001a13',
  },
  teal: {
    50: '#e0f8fc',
    100: '#9beef7',
    200: '#4fd8e5',
    300: '#27b9c4',
    400: '#109ba5',
    500: '#007c83',
    600: '#005b62',
    700: '#004045',
    800: '#002b2e',
    900: '#00191b',
  },
  blue: {
    50: '#ebf4fe',
    100: '#cae2fc',
    200: '#9bcbfa',
    300: '#57aefb',
    400: '#2f91ef',
    500: '#306dca',
    600: '#2f5091',
    700: '#263766',
    800: '#1b2542',
    900: '#101527',
  },
  violet: {
    50: '#f4f2fe',
    100: '#e1dbfd',
    200: '#c9befc',
    300: '#ad9bfb',
    400: '#9978f9',
    500: '#7c51e7',
    600: '#5e36b8',
    700: '#402979',
    800: '#2a1e4e',
    900: '#18122c',
  },
  purple: {
    50: '#f9f1fe',
    100: '#eed8fd',
    200: '#e1b6fc',
    300: '#d38bfb',
    400: '#c85bf9',
    500: '#ae27e0',
    600: '#8514ac',
    700: '#60077e',
    800: '#44005a',
    900: '#2a003a',
  },
  pink: {
    50: '#fef1f4',
    100: '#fdd6e1',
    200: '#fcb2c7',
    300: '#f984aa',
    400: '#f25090',
    500: '#cd2a74',
    600: '#9d1658',
    700: '#700c42',
    800: '#4e052b',
    900: '#32011a',
  },
}
