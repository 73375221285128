import React from 'react'

export function usePrevious<TValue>(value: TValue) {
  const ref = React.useRef<TValue>()

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
