import { Link } from 'react-router-dom'

import { SelectItem, SelectItemGroup } from './types'

export function getSelectItemProps(item: SelectItem<unknown>, inRouterContext?: boolean) {
  if (item.href) {
    if (inRouterContext) return { as: Link, to: item.href, reloadDocument: item.reloadDocument }
    else return { as: 'a', href: item.href }
  }

  return {}
}

export function isSelectItem<ValueType>(item: unknown): item is SelectItem<ValueType> {
  return !!(item?.hasOwnProperty('label') && item?.hasOwnProperty('value')) && !item?.hasOwnProperty('items')
}

export function isSelectItemGroup<ValueType>(item: unknown): item is SelectItemGroup<ValueType> {
  return !!item?.hasOwnProperty('items')
}

function matchesSearchTerm<ValueType>(item: SelectItem<ValueType>, searchTerm: string) {
  const lowerSearch = searchTerm.toLowerCase()

  return (
    (typeof item.value === 'string' && item.value.toLowerCase().includes(lowerSearch)) ||
    item.label.toLowerCase().includes(lowerSearch)
  )
}

function isSameItem(obj1: unknown, obj2?: unknown) {
  if (isSelectItem(obj1) && isSelectItem(obj2)) return obj1.value === obj2.value
  else if (isSelectItem(obj1)) return obj1.value === obj2
  return obj1 === obj2
}

/**
 * Used in combobox to find matching items
 */
export function filterSelectOptions<ValueType, ItemType extends SelectItem<ValueType> | SelectItemGroup<ValueType>>(
  items: ItemType[] | undefined,
  searchTerm: string
) {
  const finalResults: ItemType[] = []

  items?.forEach((item) => {
    if (isSelectItemGroup(item)) {
      const filtered = item.items.filter((option) => matchesSearchTerm(option, searchTerm))
      if (filtered.length) finalResults.push({ ...item, items: filtered })
    } else if (matchesSearchTerm(item, searchTerm)) {
      finalResults.push(item)
    }
  })

  return finalResults
}

/**
 * Used in select to allow users to pass any kind of valid value
 */
export function findSelectedValue<ValueType, ItemType>(
  items: ItemType[] = [],
  value?: ValueType | SelectItem<ValueType>
) {
  for (const item of items) {
    if (isSelectItemGroup(item)) {
      const foundItem = item.items.find((option) => isSameItem(option, value)) as SelectItem<ValueType>
      if (foundItem) return foundItem
    } else if (isSameItem(item, value)) {
      return item as SelectItem<ValueType>
    }
  }
}

/**
 * Used for native selects to match against user provided value
 */
export function findSelectedIndex<ValueType, ItemType>(
  items: ItemType[] = [],
  value?: ValueType | SelectItem<ValueType>
) {
  for (let index = 0; index < items.length; index++) {
    const item = items[index]

    if (isSelectItemGroup(item)) {
      const itemIndex = item.items.findIndex((option) => isSameItem(option, value))
      if (itemIndex > -1) return `${index}${itemIndex}`
    } else if (isSameItem(item, value)) {
      return index
    }
  }

  return -1
}

export function flattenItems<ItemType>(items: ItemType[]) {
  return items.flatMap((itemOrGroup) =>
    isSelectItemGroup(itemOrGroup) ? (itemOrGroup.items as ItemType[]) : itemOrGroup
  )
}
