import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { SelectItem, SelectItemGroup } from './types'
import { flattenItems, isSelectItemGroup } from './utils'

interface NativeSelectProps<ItemType> extends Omit<React.HTMLProps<HTMLSelectElement>, 'onChange'> {
  items: ItemType[]
  onChange?: (item: ItemType) => void
}

export function NativeSelect<ItemType extends SelectItemGroup<unknown> | SelectItem<unknown>>({
  items,
  onChange,
  placeholder = 'Select value',
  ...props
}: NativeSelectProps<ItemType>) {
  const showPlaceholder = props.value === -1
  const handleNativeSelectChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const itemIndex = e.target.selectedIndex + (showPlaceholder ? -1 : 0)
      const selectedItem = flattenItems(items)[itemIndex] as SelectItem<unknown>

      if (!selectedItem) return

      onChange?.(selectedItem as any)

      if (!selectedItem.href || !selectedItem.reloadDocument) {
        return
      }

      if (typeof selectedItem.href === 'string') {
        window.location.href = selectedItem.href
        return
      }

      const newUrl = new URL(selectedItem.href.pathname!, window.location.href)
      newUrl.search = selectedItem.href.search ?? ''
      newUrl.hash = selectedItem.href.hash ?? ''

      window.location.href = newUrl.toString()
    },
    [items, onChange, showPlaceholder]
  )

  return (
    <select
      {...props}
      tabIndex={-1}
      className="absolute bottom-0 left-0 right-0 top-0 w-full appearance-none opacity-0"
      onChange={handleNativeSelectChange}
    >
      {showPlaceholder && <option hidden>{placeholder}</option>}
      {items.map((item, itemIndex) => {
        if (isSelectItemGroup(item)) {
          return (
            <optgroup key={item.label} label={item.label}>
              {item.items.map((option, optionIndex) => (
                <option key={optionIndex} value={`${itemIndex}${optionIndex}`}>
                  {option.label}
                </option>
              ))}
            </optgroup>
          )
        }

        return (
          <option key={itemIndex} value={itemIndex}>
            {item.label}
          </option>
        )
      })}
    </select>
  )
}

export function NativeSelectInRouterContext<ItemType extends SelectItemGroup<unknown> | SelectItem<unknown>>(
  props: NativeSelectProps<ItemType>
) {
  const navigate = useNavigate()

  const onChangeWithNavigate = React.useCallback(
    (item: SelectItem<unknown>) => {
      props.onChange?.(item as any)

      if (item.href) navigate(item.href)
    },
    // We don’t need the whole props here, just onChange and items
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, props.onChange, props.items]
  )

  return <NativeSelect {...props} items={props.items as any} onChange={onChangeWithNavigate} />
}
