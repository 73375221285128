import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { OptionButton, OptionButtonProps } from '../OptionButton'
import { BUTTON_DEFAULT_ELEMENT, ButtonComponentProps } from '../button'

interface SelectOptionProps<ValueType> extends OptionButtonProps {
  value: ValueType
}

export const SelectOption = React.forwardRef(function SelectOption<
  ValueType,
  TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT,
>(
  { as, ...props }: Omit<ButtonComponentProps<TagType>, 'value' | 'theme'> & SelectOptionProps<ValueType>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const context = Ariakit.useSelectContext()!
  const isComboBox = !!context.combobox

  return (
    <Ariakit.SelectItem
      ref={ref}
      {...props}
      render={isComboBox ? <Ariakit.ComboboxItem render={<OptionButton as={as} />} /> : <OptionButton as={as} />}
    />
  )
})
