import classnames from 'classnames'
import * as React from 'react'

interface DialogFooterProps extends React.HTMLProps<HTMLDivElement> {
  padding?: boolean
}

/**
 * Footer container which renders border and aligns items to right
 */
export function DialogFooter({ padding = true, ...props }: React.PropsWithChildren<DialogFooterProps>) {
  return (
    <div
      {...props}
      className={classnames(
        props.className,
        'flex shrink-0 justify-end space-x-2 border-t border-gray-100 dark:border-gray-600',
        { 'p-3': padding }
      )}
    >
      {props.children}
    </div>
  )
}
