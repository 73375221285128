import classnames from 'classnames'
import * as React from 'react'

import { TABLE_STICKY_CELL_CLASS_NAME } from './constants'
import { TableAlign } from './types'

export interface TableCellProps extends React.HTMLProps<HTMLTableCellElement> {
  isFirst?: boolean
  isLast?: boolean
  align?: TableAlign
  hasPadding?: boolean
  hasLeading?: boolean
  isSticky?: boolean
}

export function TableCell({
  isFirst,
  isLast,
  align = TableAlign.Left,
  hasPadding = true,
  hasLeading = true,
  isSticky,
  className,
  ...props
}: React.PropsWithChildren<TableCellProps>) {
  return (
    <td
      {...props}
      className={classnames(className, {
        [TABLE_STICKY_CELL_CLASS_NAME]: isSticky,
        'is-first-cell': isFirst,
        'is-last-cell': isLast,
        'px-1': hasPadding,
        'p-0': !hasPadding,
        'leading-tight': hasLeading,
        'text-center': align === TableAlign.Center,
        'text-right': align === TableAlign.Right,
      })}
    />
  )
}
