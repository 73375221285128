import * as React from 'react'

import * as storage from '../services/local-storage'

/**
 * Hook to synchronize localStorage.setItem across all components on the page on all open tabs
 */
export function useSyncLocalStorage<ValueType>(storageKey: string, defaultValue: ValueType) {
  const [state, setState] = React.useState<ValueType>(storage.getValue(storageKey, defaultValue))

  React.useEffect(() => {
    const subscription = storage.subscribeToChanges(storageKey, defaultValue, setState)

    return () => {
      subscription.unsubscribe()
    }
  }, [storageKey, defaultValue])

  const setValue = React.useCallback(
    (valueGetter: React.SetStateAction<ValueType>) => storage.setValue(storageKey, valueGetter, defaultValue),
    [defaultValue, storageKey]
  )

  return {
    state,
    setState: setValue,
  }
}
