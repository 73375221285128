import classNames from 'classnames'
import * as React from 'react'
import { Link, To } from 'react-router-dom'

import { MenuGroup } from '../MenuGroup'
import { Button, ButtonProps, ButtonRoundingType, ButtonSizeType } from '../button'
import { Icon } from '../icon'
import { LabelWithDelayedSpinner } from '../label-with-delayed-spinner'
import { SelectItem } from '../select'
import { Select, SelectOption } from '../select'

export type TimeframeBaseOption<ValueType = string> = SelectItem<ValueType> & {
  labelShort?: string
  labelPageTitle?: string
  href?: To
  withReload?: boolean
}

interface Props<TimeFrame> {
  chartIndex: number
  isCompactView: boolean
  isCompactOnly: boolean
  timeFrameGroups: Array<{ label: string; timeframes: TimeFrame[] }>
  selectedTimeframe: string
  onTimeframeSelect: (value: TimeFrame) => void
  favoriteTimeframes: string[]
  onFavoriteTimeframeToggle: (value: TimeFrame) => void
  rounding?: ButtonRoundingType
  size?: ButtonSizeType
  isLoading?: boolean
  isFavoritesEnabled: boolean
  theme?: ButtonProps['theme']
}

const TIMEFRAME_TOOLTIP = 'Interval'

export function TimeframeSelect<TimeFrame extends TimeframeBaseOption>({
  size,
  chartIndex,
  timeFrameGroups,
  selectedTimeframe,
  favoriteTimeframes,
  isLoading = false,
  isCompactView,
  isCompactOnly,
  onTimeframeSelect,
  onFavoriteTimeframeToggle,
  rounding,
  isFavoritesEnabled,
  theme = 'chip',
}: Props<TimeFrame>) {
  const flatTimeframes = React.useMemo(() => timeFrameGroups.flatMap(({ timeframes }) => timeframes), [timeFrameGroups])

  const getSelectedTimeframeLabel = React.useCallback(() => {
    if (isCompactView) {
      const selectedTimeframeItem = flatTimeframes.find(({ value }) => value === selectedTimeframe)
      return selectedTimeframeItem?.labelShort ?? selectedTimeframeItem?.label
    }
    return ''
  }, [flatTimeframes, isCompactView, selectedTimeframe])

  return (
    <Select
      className={classNames({ 'w-full': theme === 'outline' })}
      theme={theme}
      size={size}
      tooltip={TIMEFRAME_TOOLTIP}
      aria-label={TIMEFRAME_TOOLTIP}
      rightContent={theme === 'outline'}
      rounding={rounding}
      active={isCompactView && !isCompactOnly ? true : undefined}
      appearance={isCompactView ? undefined : 'square'}
      triggerContent={getSelectedTimeframeLabel()}
      value={flatTimeframes.find((item) => item.value === selectedTimeframe)}
      leftContent={
        <div className="relative h-4 w-4">
          <LabelWithDelayedSpinner label={<Icon name="interval" />} isLoading={isLoading && isCompactView} />
        </div>
      }
      onChange={(item) => onTimeframeSelect(item as TimeFrame)}
      data-testid={`chart-${chartIndex}-timeframe-select`}
    >
      {timeFrameGroups.flatMap(({ timeframes }, groupIndex) => (
        <MenuGroup key={`separator-${groupIndex}-${favoriteTimeframes.length}`}>
          {timeframes.map((item) => {
            const isIconButtonActive = favoriteTimeframes.includes(item.value)
            let linkProps = {}
            if (item.href) {
              linkProps = {
                as: Link,
                to: item.href,
                reloadDocument: !!item.withReload,
              }
            }
            return (
              <SelectOption
                {...linkProps}
                value={item}
                rounding={rounding}
                key={`${item.value}${favoriteTimeframes.length}`}
                data-testid={`chart-${chartIndex}-timeframe-select-${item.value}`}
                rightContent={
                  isFavoritesEnabled ? (
                    <Button
                      as="div"
                      theme="transparent"
                      appearance="square"
                      data-testid={`chart-${chartIndex}-timeframe-select-${item.value}-toggle-favorite`}
                      onMouseDown={(ev: React.MouseEvent<HTMLDivElement>) => {
                        ev.stopPropagation()
                        ev.preventDefault()
                        onFavoriteTimeframeToggle(item)
                      }}
                      className={classNames('group -mr-2', {
                        'text-gray-300': !isIconButtonActive,
                        'text-yellow-200': isIconButtonActive,
                      })}
                    >
                      <Icon
                        name="star"
                        className={classNames({
                          'group-hover:hidden': isIconButtonActive,
                          'hidden group-hover:block': !isIconButtonActive,
                        })}
                      />
                      <Icon
                        name="starOutlined"
                        className={classNames({
                          'group-hover:hidden': !isIconButtonActive,
                          'hidden group-hover:block': isIconButtonActive,
                        })}
                      />
                    </Button>
                  ) : undefined
                }
              >
                {item.label}
              </SelectOption>
            )
          })}
        </MenuGroup>
      ))}
    </Select>
  )
}
