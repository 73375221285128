import * as React from 'react'
import { CalendarProps } from 'react-calendar'

import { Box } from '../box'
import { Popover, PopoverTrigger, usePopoverState } from '../popover'
import { Spinner } from '../spinner'

const DatePickerComponent = React.lazy(() => import('./picker'))

type RangePickerCallback = (value: [Date, Date], ev: React.ChangeEvent<HTMLInputElement>) => void
type PickerCallback = (value: Date, ev: React.ChangeEvent<HTMLInputElement>) => void

interface DatePickerProps extends Omit<CalendarProps, 'onChange'> {
  /**
   * Trigger element for the picker
   */
  children: JSX.Element

  onChange: PickerCallback | RangePickerCallback
}

export function StatelessDatePicker({
  state,
  children,
  ...props
}: DatePickerProps & { state: ReturnType<typeof usePopoverState>; zIndex?: string }) {
  return (
    <>
      <PopoverTrigger state={state} render={children} />
      <Popover state={state} theme="none" aria-label="Date picker">
        <React.Suspense
          fallback={
            <Box rounding="none" className="flex h-[202px] w-[235px] items-center justify-center">
              <Spinner width={16} />
            </Box>
          }
        >
          <DatePickerComponent
            {...props}
            locale="en-US"
            minDetail="year"
            onChange={(value: any, ev: React.ChangeEvent<HTMLInputElement>) => {
              state.hide()
              props.onChange?.(value, ev)
            }}
          />
        </React.Suspense>
      </Popover>
    </>
  )
}

export function DatePicker(props: DatePickerProps) {
  const popover = usePopoverState()

  return <StatelessDatePicker {...props} state={popover} />
}
