import debounce from 'lodash.debounce'
import * as React from 'react'

import { useIsMounted } from './use-is-mounted'
import { useResizeObserver } from './use-resize-observer'

export function useElementMeasure(elementOverride: HTMLElement | null = null) {
  const ResizeObserver = useResizeObserver()
  const getIsMounted = useIsMounted()
  const [elementRef, setElementRef] = React.useState(elementOverride)
  const [elementWidth, setElementWidth] = React.useState<number>(elementRef?.offsetWidth ?? 0)
  const [elementHeight, setElementHeight] = React.useState<number>(elementRef?.offsetHeight ?? 0)

  React.useEffect(() => {
    if (!ResizeObserver) {
      return
    }

    const measureElement = () => {
      if (!getIsMounted()) {
        return
      }
      const newElementWidth = elementRef?.offsetWidth
      const newElementHeight = elementRef?.offsetHeight
      if (newElementWidth !== elementWidth) {
        setElementWidth(newElementWidth ?? 0)
      }
      if (newElementHeight !== elementHeight) {
        setElementHeight(newElementHeight ?? 0)
      }
    }
    const measureElementDebounced = debounce(measureElement, 200)

    const resizeObserver = new ResizeObserver((entries) => {
      /**
       * the reason for requestAnimationFrame is described here https://github.com/quasarframework/quasar/issues/2233
       * their approach was mostly to intercept and silence the error but I managed to avoid the error using requestAnimationFrame
       * */
      window.requestAnimationFrame(() => {
        if (Array.isArray(entries) && entries.length > 0) {
          measureElementDebounced()
        }
      })
    })

    if (elementRef) {
      resizeObserver.observe(elementRef)
    }

    measureElement()

    return () => {
      measureElementDebounced.cancel()
      resizeObserver?.disconnect()
    }
  }, [ResizeObserver, elementWidth, elementRef, elementHeight, getIsMounted])

  return { setElementRef: elementOverride ? () => {} : setElementRef, elementWidth, elementHeight }
}
