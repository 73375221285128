import classnames from 'classnames'
import * as React from 'react'

import { Delayed } from './delayed'
import { Spinner } from './spinner'

interface Props {
  label: React.ReactNode
  isLoading: boolean
}

export function LabelWithDelayedSpinner({ label, isLoading }: Props) {
  return (
    <>
      <span className={classnames({ invisible: isLoading })}>{label}</span>
      {isLoading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <Delayed delayComponent={<span>{label}</span>}>
            <Spinner width={16} />
          </Delayed>
        </div>
      )}
    </>
  )
}
