import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { usePopoverPlacement } from '../../hooks/usePopoverPlacement'
import { blurWithoutScroll, focusWithoutScroll } from '../../util'
import { ZIndexContext } from '../dialog'
import { PopoverStateReturn } from './hooks'

export enum PopoverRounding {
  none = '',
  regular = 'rounded-lg',
}

export enum PopoverTheme {
  none = '',
  default = 'flex flex-col overflow-hidden border border-primary bg-primary shadow-modal dark:shadow-modal-dark',
}

export interface PopoverProps extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'>, Ariakit.PopoverProps {
  // State is intentionally not marked as optional so you get error when you don’t provide it
  /**
   * Returned state from `usePopoverState`. Set `undefined` if you use a `PopoverProvider` wrapper
   */
  state: PopoverStateReturn | undefined

  /**
   * When true, the popover will be rendered in a portal and all outside elements won’t be interactable
   *
   * @default true
   */
  modal?: boolean

  /**
   * Set rounding for the popover
   *
   * @default regular
   */
  rounding?: keyof typeof PopoverRounding

  /**
   * Popover theme
   */
  theme?: keyof typeof PopoverTheme

  /**
   * The amount of space between button and its popover
   *
   * @default 4
   */
  gutter?: number

  /**
   * Hide the popover when user presses ESC
   *
   * @default true
   */
  hideOnEsc?: boolean

  /**
   * Show backdrop on the popover. Provide your own element if you want to add background or animation
   *
   */
  backdrop?: false | JSX.Element

  /**
   * Do not render the items when the popover is not visible
   *
   * @default true
   */
  unmountOnHide?: boolean

  /**
   * By default the popover hides when backdrop is clicked. When backdrop is disabled you can use this prop to
   * enable hiding when clicking outside of the popover
   */
  hideOnInteractOutside?: boolean

  /**
   * Callback before the popover starts hiding. Event can be prevented which keeps the popover open
   * This callback isn’t called on backdrop click. Use `backdrop={<div onMouseDown={…} />}` to react on backdrop clicks
   */
  onClose?: (event: Event) => void

  /**
   * Set which element gets focus when the dialog visibility changes. If `false`, the focus stays on the previous element.
   * Defaults to focusing without scrolling the page or keeping the focus on trigger if input-like.
   */
  autoFocusOnShow?: false | (() => boolean)

  /**
   * Set which element gets focus when the dialog visibility changes. If `false`, the focus stays on the previous element.
   * Defaults to focusing without scrolling the page or keeping the focus on trigger if input-like.
   */
  autoFocusOnHide?: false | (() => boolean)
}

export function Popover({
  state,
  modal = true,
  rounding = 'regular',
  theme = 'default',
  gutter = 4,
  hideOnEsc = true,
  backdrop,
  unmountOnHide = true,
  onClose,
  'aria-label': ariaLabel,
  hideOnInteractOutside,
  autoFocusOnShow,
  autoFocusOnHide,
  children,
  ...props
}: PopoverProps) {
  const context = Ariakit.usePopoverContext()
  const popoverState = state ?? context
  const { placement, zIndex, updatePosition } = usePopoverPlacement(popoverState)
  const isPopoverOpen = popoverState?.useState('open')
  const [isFullyOpen, setIsFullyOpen] = React.useState(isPopoverOpen)

  // Check if the trigger element is input-like so we can keep it focused
  const triggerElement = React.useMemo(
    () => (isPopoverOpen ? popoverState!.getState().disclosureElement : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPopoverOpen]
  )
  const isTriggerInput = triggerElement instanceof HTMLInputElement || triggerElement instanceof HTMLTextAreaElement

  return (
    <Ariakit.Popover
      {...props}
      store={popoverState}
      portal
      modal={modal}
      preventBodyScroll={false}
      hideOnEscape={hideOnEsc}
      unmountOnHide={unmountOnHide}
      // Space between the button and its popover
      gutter={gutter}
      // Manage the focus ourselves so we can prevent scrolling when focusing the disclosure element
      autoFocusOnShow={autoFocusOnShow ?? (isTriggerInput ? false : focusWithoutScroll)}
      autoFocusOnHide={autoFocusOnHide ?? blurWithoutScroll}
      // Mark the trigger as part of the dialog so it can stay focused if input-like
      getPersistentElements={isTriggerInput ? () => [triggerElement!] : undefined}
      hideOnInteractOutside={hideOnInteractOutside ?? (!modal && !backdrop)}
      // Update zIndex when popover position changes
      updatePosition={updatePosition}
      // E2E tests don’t wait for transitions to complete and it could lead to bugs with other popover components (ResizeObserver loop exceeded)
      // or visual diffs due a screenshot being captured while the popover still in transition
      // If you encounter this problem, just do `cy.get('[data-fully-open=true]').should('exist')` before continuing to wait for the popover
      data-fully-open={isFullyOpen}
      aria-label={ariaLabel}
      onTransitionEnd={(ev) => {
        if (ev.target === ev.currentTarget && isPopoverOpen) setIsFullyOpen(true)
      }}
      onClose={(ev) => {
        onClose?.(ev)
        // prevented means the dialog didn’t close
        if (!ev.defaultPrevented) setIsFullyOpen(false)
      }}
      // Custom event prevents scrolling issues
      // See website PR #1968
      backdrop={
        backdrop ?? (
          <div
            onMouseDown={(ev: React.MouseEvent) => {
              ev.preventDefault()
              popoverState?.hide()
            }}
          />
        )
      }
      className={classnames(
        props.className,
        zIndex,
        PopoverRounding[rounding],
        PopoverTheme[theme],
        'custom-scrollbar absolute max-w-[--popover-available-width] overscroll-contain outline-none',
        // Animations
        'opacity-0 transition duration-[250ms] data-[enter]:translate-y-0 data-[enter]:opacity-100',
        {
          'max-h-[calc(var(--popover-available-height)-env(safe-area-inset-bottom))] motion-safe:-translate-y-1':
            placement?.startsWith('bottom'),
          'max-h-[calc(var(--popover-available-height)-env(safe-area-inset-top))] motion-safe:translate-y-1':
            placement?.startsWith('top'),
        }
      )}
    >
      <ZIndexContext.Provider value="z-dialog">{children}</ZIndexContext.Provider>
    </Ariakit.Popover>
  )
}
