import classNames from 'classnames'
import throttle from 'lodash.throttle'
import * as React from 'react'

import { useElementMeasure } from '../../hooks/use-element-measure'

interface Props {
  children: React.ReactElement
  className?: string
  shouldScrollToEnd?: boolean
  hasBorder?: boolean
  hasHeaderShadow?: boolean
  cropFillerClassName?: string
}

enum ShadowPosition {
  none,
  left,
  right,
  leftAndRight,
}

interface TableScrollShadowProps {
  side: ShadowPosition.left | ShadowPosition.right
  position: { top?: number; left?: number; right?: number }
  height: number
  className?: string
  isVisible: boolean
  hasBorder?: boolean
}

function TableScrollShadow({
  className,
  side,
  position,
  height,
  isVisible,
  hasBorder = false,
}: TableScrollShadowProps) {
  return (
    <div
      className={classNames(
        'pointer-events-none absolute bottom-0 z-20 w-3 from-gray-900/10 dark:from-gray-900/50',
        className,
        {
          'opacity-100 transition-opacity duration-200': isVisible,
          'opacity-0': !isVisible,
          'bg-gradient-to-r': side === ShadowPosition.left,
          'bg-gradient-to-l': side === ShadowPosition.right,
          'border-primary': hasBorder,
          'border-l': hasBorder && side === ShadowPosition.left,
          'border-r': hasBorder && side === ShadowPosition.right,
        }
      )}
      style={{
        ...position,
        height,
      }}
    />
  )
}

export function TableStickyColumnWrapper({
  children,
  className,
  shouldScrollToEnd,
  cropFillerClassName = 'w-3 bg-primary',
  hasBorder = false,
  hasHeaderShadow = false,
}: Props) {
  const { setElementRef, elementHeight } = useElementMeasure()
  const tableWrapperRef = React.useRef<HTMLDivElement>(null)
  const [shadowPosition, setShadowPosition] = React.useState(ShadowPosition.none)
  const [shadowDimensions, setShadowDimensions] = React.useState({ left: 0, top: 0, height: 0 })

  const tableShadowMargin = hasBorder ? 1 : 0 // margins to prevent covering borders with the shadow

  React.useEffect(() => {
    const tableWrapperElement = tableWrapperRef.current
    if (!tableWrapperElement) return

    const onScroll = throttle(function () {
      const tableWrapperElement = tableWrapperRef.current
      if (!tableWrapperElement) return

      const tableWrapperElementScrollLeft = Math.ceil(tableWrapperElement.scrollLeft)

      let newShadowPosition = ShadowPosition.none
      const hasScrollSpaceToLeft = tableWrapperElementScrollLeft > 0
      const hasScrollSpaceToRight =
        Math.ceil(tableWrapperElement.offsetWidth) + tableWrapperElementScrollLeft < tableWrapperElement.scrollWidth
      if (hasScrollSpaceToLeft && hasScrollSpaceToRight) {
        newShadowPosition = ShadowPosition.leftAndRight
      } else if (hasScrollSpaceToLeft) {
        newShadowPosition = ShadowPosition.left
      } else if (hasScrollSpaceToRight) {
        newShadowPosition = ShadowPosition.right
      }

      setShadowPosition(newShadowPosition)
    }, 100)

    if (shouldScrollToEnd) {
      tableWrapperElement.scrollTo({
        left: tableWrapperElement.scrollWidth,
      })
    }

    onScroll()

    tableWrapperElement.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)

    return () => {
      tableWrapperElement.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const tableWrapperElement = tableWrapperRef.current
    if (!tableWrapperElement) return

    setElementRef(tableWrapperElement)
    const financialsTable = tableWrapperElement.querySelector('table')
    const headHeight = financialsTable?.querySelector('thead')?.clientHeight ?? 0
    const firstColum = financialsTable?.querySelector('tbody tr > td')
    const firstColumnWidth = firstColum?.getBoundingClientRect().width ?? 0

    const topOffset = hasHeaderShadow ? 0 : headHeight + tableShadowMargin
    setShadowDimensions({
      left: firstColumnWidth,
      top: topOffset,
      height: (financialsTable?.clientHeight ?? 0) - (hasHeaderShadow ? 0 : topOffset + tableShadowMargin),
    })
  }, [setElementRef, elementHeight, tableShadowMargin, hasHeaderShadow])

  const { radiusCropFillerStyle, shadowLeftStyle, shadowRightStyle } = React.useMemo(
    () => ({
      radiusCropFillerStyle: {
        top: shadowDimensions.top - tableShadowMargin,
        height: shadowDimensions.height + 2 * tableShadowMargin,
      },
      shadowLeftStyle: {
        top: shadowDimensions.top,
        left: shadowDimensions.left,
      },
      shadowRightStyle: {
        top: shadowDimensions.top,
        right: 0,
      },
    }),
    [shadowDimensions, tableShadowMargin]
  )

  return (
    <div className={classNames('relative max-w-full', className)}>
      {/* Width of radius crop filler needs to be the same as radius */}
      <div
        className={classNames(cropFillerClassName, 'absolute left-0 top-0 z-10 h-full')}
        style={radiusCropFillerStyle}
      />
      <TableScrollShadow
        isVisible={[ShadowPosition.left, ShadowPosition.leftAndRight].includes(shadowPosition)}
        side={ShadowPosition.left}
        position={shadowLeftStyle}
        height={shadowDimensions.height}
        hasBorder={hasBorder}
      />
      <div className="relative max-w-full overflow-auto whitespace-nowrap" ref={tableWrapperRef}>
        {children}
      </div>
      <TableScrollShadow
        isVisible={[ShadowPosition.right, ShadowPosition.leftAndRight].includes(shadowPosition)}
        side={ShadowPosition.right}
        position={shadowRightStyle}
        height={shadowDimensions.height}
        hasBorder={hasBorder}
      />
    </div>
  )
}
