import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import classnames from 'classnames'
import * as React from 'react'

import { isMobile } from '../../../app/shared/isMobile'

interface DialogBodyProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Whether or not the dialog body will have padding
   *
   * @default true
   */
  hasPadding?: boolean
}

/**
 * Content wrapper which handles scrolling on mobile
 */
export function DialogBody({ hasPadding = true, ...props }: React.PropsWithChildren<DialogBodyProps>) {
  const scrollDivRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const scrollBox = scrollDivRef.current

    if (isMobile() && scrollBox) {
      disableBodyScroll(scrollBox)

      return () => enableBodyScroll(scrollBox)
    }
  }, [])

  return (
    <div
      ref={scrollDivRef}
      {...props}
      className={classnames(props.className, 'overflow-y-auto', {
        'p-4': hasPadding,
      })}
    >
      {props.children}
    </div>
  )
}
