import * as Ariakit from '@ariakit/react'
import classNames from 'classnames'
import * as React from 'react'

import { usePopoverPlacement } from '../../hooks/usePopoverPlacement'
import { blurWithoutScroll, focusWithoutScroll, preventDefault } from '../../util'
import { Input } from '../input'

enum ListRounding {
  none = '',
  regular = 'rounded-lg',
}

enum ListTheme {
  none = '',
  default = 'border border-primary bg-primary',
}

export interface SelectListProps {
  /**
   * Internal prop which enables input at the top of the popover list
   */
  isComboBox?: boolean

  /**
   * Set rounding for the popover list
   *
   * @default regular
   */
  rounding?: keyof typeof ListRounding

  /**
   * Popover theme
   */
  theme?: keyof typeof ListTheme

  /**
   * The amount of space between button and its popover
   *
   * @default 4
   */
  gutter?: number

  /**
   * Change how the items are laid out
   *
   * @default vertical
   */
  orientation?: 'vertical' | 'horizontal'

  /**
   * Custom popover class
   */
  className?: string

  /**
   * Internal prop used to display no results when automatic `item` filtering doesn’t return any items
   */
  hasItemChildren?: boolean

  /**
   * Do not render the items when the popover is not visible
   *
   * @default false
   */
  unmountOnHide?: boolean

  /**
   * Test id to use
   */
  parentTestId?: string
}

export function SelectList({
  isComboBox,
  rounding = 'regular',
  theme = 'default',
  gutter = 4,
  orientation = 'vertical',
  className,
  unmountOnHide = false,
  hasItemChildren,
  parentTestId,
  children,
}: React.PropsWithChildren<SelectListProps>) {
  const context = Ariakit.useSelectContext()!
  const { placement, zIndex, updatePosition } = usePopoverPlacement(context)
  const ListElement = React.useMemo(
    () => (isComboBox ? Ariakit.ComboboxList : (wrapperProps: any) => <div {...wrapperProps} />),
    [isComboBox]
  )
  const [isFullyOpen, setIsFullyOpen] = React.useState(false)
  const hasChildren = hasItemChildren || React.Children.count(children) > 0

  return (
    <Ariakit.SelectPopover
      // Render in a portal to escape overflow
      portal
      modal
      preventBodyScroll={false}
      // Do not keep the children mounted when the popover is closed
      unmountOnHide={unmountOnHide}
      // Space between button and popover
      gutter={gutter}
      // Manage the focus ourselves so we can prevent scrolling when focusing the disclosure element
      autoFocusOnShow={focusWithoutScroll}
      autoFocusOnHide={blurWithoutScroll}
      // Custom event prevents scrolling issues
      // See website PR #1968
      backdrop={<div onMouseDown={preventDefault} />}
      // Update zIndex when popover position changes
      updatePosition={updatePosition}
      data-testid={parentTestId ? `${parentTestId}-list` : `select-list`}
      // E2E tests don’t wait for transitions to complete and it could lead to bugs with other popover components (ResizeObserver loop exceeded)
      // or visual diffs due a screenshot being captured while the popover still in transition
      // If you encounter this problem, just do `cy.get('[data-fully-open=true]').should('exist')` before continuing to wait for the popover
      data-fully-open={isFullyOpen}
      onTransitionEnd={(ev) => {
        if (ev.target === ev.currentTarget && context.getState().open) setIsFullyOpen(true)
      }}
      onClose={(ev) => {
        // prevented means the dialog didn’t close
        if (!ev.defaultPrevented) setIsFullyOpen(false)
      }}
      className={classNames(
        className,
        zIndex,
        ListRounding[rounding],
        ListTheme[theme],
        'custom-scrollbar absolute flex flex-col overflow-hidden shadow-modal outline-none dark:shadow-modal-dark',
        // These are set by popper, we just need to make sure we don’t encroach on the safe insets
        'min-w-[--popover-anchor-width] max-w-[--popover-available-width]',
        // Animations
        'opacity-0 transition duration-[250ms] data-[enter]:translate-y-0 data-[enter]:opacity-100',
        {
          // When in combobox mode, the padding is smaller so we can render a gradient under the combobox input.
          // Change the custom scrollbar offset so the top of the first item fits the top of our scrollbar
          '[--scrollbar-offset-top:theme(spacing.1)]': isComboBox,
          '[--scrollbar-radius:0]': ListRounding[rounding] === ListRounding.none,
          // Direction based styles
          'max-h-[calc(var(--popover-available-height)-env(safe-area-inset-bottom))] motion-safe:-translate-y-1':
            placement?.startsWith('bottom'),
          'max-h-[calc(var(--popover-available-height)-env(safe-area-inset-top))] motion-safe:translate-y-1':
            placement?.startsWith('top'),
        }
      )}
    >
      {isComboBox && (
        <div className="min-w-29 shrink-0">
          <div className="p-1.5 pb-0.5">
            <Ariakit.Combobox
              autoSelect
              placeholder="Search…"
              render={
                <Input
                  rounding={rounding === 'none' ? 'none' : undefined}
                  // Fixes a problem with input unnecessarily stretching the popover
                  inputClass="!w-0"
                />
              }
            />
          </div>
          <div className="to-[theme(backgroundColor.primary)]/0 absolute h-1 w-full bg-gradient-to-b from-[theme(backgroundColor.primary)]" />
        </div>
      )}
      <ListElement
        tabIndex={-1}
        className={classNames(
          'flex max-h-[500px] grow scroll-py-1.5 overflow-y-auto overscroll-contain p-1.5 outline-none',
          {
            'flex-col': orientation === 'vertical',
            'flex-row': orientation === 'horizontal',
            'pt-1': isComboBox,
          }
        )}
      >
        {hasChildren ? children : <div className="py-1 text-center text-2xs">Nothing to select</div>}
      </ListElement>
    </Ariakit.SelectPopover>
  )
}
