import classnames from 'classnames'
import * as React from 'react'

import { useElementMeasure } from '../../hooks/use-element-measure'
import { Box, BoxProps } from '../box'
import { DialogDragContext } from './hooks'

/**
 * Public props for the component
 */
export type DialogBoxProps = BoxProps

interface DialogBoxOwnProps extends DialogBoxProps {
  /**
   * This callback serves as a way to account for the time between dialog close
   * and animation finish. The box isn’t rendered when the dialog closes but we still
   * want to display content when the dialog is closing
   */
  onUnmount?: () => void
}

/**
 * Dialog content container
 */
export function DialogBox({ onUnmount, style, ...props }: React.PropsWithChildren<DialogBoxOwnProps>) {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const { setElementRef, elementHeight } = useElementMeasure()
  const dialogDrag = React.useContext(DialogDragContext)

  React.useEffect(() => {
    const onResize = () => {
      const { top = 0, bottom = 0, left = 0, right = 0 } = dialogDrag.handleRef?.current?.getBoundingClientRect() ?? {}
      const { clientHeight, clientWidth } = window.document.documentElement
      if (top < 0 || bottom > clientHeight || left < 0 || right > clientWidth) {
        dialogDrag.resetBoxPosition()
      }
    }

    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
      onUnmount?.()
    }
    // Set up an effect which only calls resetBoxPosition which is ref fn with state setter call and the unmount callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setElementRef(wrapperRef.current)
  }, [setElementRef])

  React.useEffect(() => {
    dialogDrag.setBoxPosition((prevPosition) => {
      const { top = 0 } = dialogDrag.handleRef?.current?.getBoundingClientRect() ?? {}
      return { ...prevPosition, y: prevPosition.y - Math.min(top, 0) }
    })
    // We want to call it only on elementHeight change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementHeight])

  const dialogBoxStyle = React.useMemo(() => {
    if (dialogDrag.enabled) {
      return { ...style, transform: `translate3d(${dialogDrag.boxPosition.x}px, ${dialogDrag.boxPosition.y}px, 0)` }
    }
    return style
  }, [style, dialogDrag])

  return (
    <Box
      {...props}
      ref={wrapperRef}
      className={classnames('relative z-10 flex max-h-full max-w-full flex-col', props.className)}
      style={dialogBoxStyle}
    >
      {props.children}
    </Box>
  )
}
