import * as Ariakit from '@ariakit/react'
import * as React from 'react'

interface PopoverTriggerProps extends Omit<Ariakit.PopoverDisclosureProps, 'store'> {
  // State is intentionally not marked as optional so you get error when you don’t provide it
  /**
   * Returned state from `usePopoverState`. Set `undefined` if you use a `PopoverProvider` wrapper
   */
  state: Ariakit.PopoverStore | undefined
}

export const PopoverTrigger = React.forwardRef(
  ({ state, ...props }: PopoverTriggerProps, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <Ariakit.PopoverDisclosure ref={ref} store={state} {...props} />
  )
)

export const PopoverAnchor = Ariakit.PopoverAnchor
