import * as React from 'react'

import { Heading, Paragraph } from '../typography'
import { Dialog, DialogProps } from './dialog'
import { DialogBody } from './dialog-body'
import { DialogStateReturn } from './hooks'

interface ConfirmationDialogProps {
  state: DialogStateReturn
  title: React.ReactNode
  actions: React.ReactNode
  'aria-label': string
  position?: DialogProps['position']
}

export function ConfirmationDialog({ position, ...props }: React.PropsWithChildren<ConfirmationDialogProps>) {
  return (
    <Dialog
      className="w-80"
      state={props.state}
      position={position}
      aria-label={props['aria-label']}
      backdrop={<div className="bg-gray-800/50 opacity-0 transition-opacity data-[enter]:opacity-100" />}
      data-testid="confirmation-dialog"
    >
      <DialogBody>
        <Heading level={4} className="mb-2 mt-0 break-words">
          {props.title}
        </Heading>
        <Paragraph size="small">{props.children}</Paragraph>
        <div className="flex shrink-0 justify-end space-x-2 pt-4">{props.actions}</div>
      </DialogBody>
    </Dialog>
  )
}
