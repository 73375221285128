import * as React from 'react'
import { Link, To } from 'react-router-dom'

import { TIMEFRAME } from '../../constants'
import { MenuGroup } from '../MenuGroup'
import { ButtonRoundingType, ButtonSizeType } from '../button'
import { Icon } from '../icon'
import { Select, SelectOption } from '../select'
import { SelectItem } from '../select/types'

export interface DateRangeBaseOption extends SelectItem<string> {
  timeframe?: TIMEFRAME
  href?: To
  withReload?: boolean
}

interface Props<DateRange> {
  chartIndex: number
  dateRangeGroups: Array<{ label?: string; dateRanges: DateRange[] }>
  selectedDateRange: string | null
  onDateRangeSelect: (value: DateRange) => void
  onCustomDateRangeClick?: () => void
  rounding?: ButtonRoundingType
  size?: ButtonSizeType
}

const DATERANGE_TOOLTIP = 'Range'

export function DateRangeSelect<DateRange extends DateRangeBaseOption>({
  size,
  chartIndex,
  dateRangeGroups,
  selectedDateRange,
  onDateRangeSelect,
  onCustomDateRangeClick,
  rounding,
}: Props<DateRange>) {
  const flatDateRanges = React.useMemo(() => dateRangeGroups.flatMap(({ dateRanges }) => dateRanges), [dateRangeGroups])

  return (
    <Select
      tooltip={DATERANGE_TOOLTIP}
      aria-label={DATERANGE_TOOLTIP}
      value={selectedDateRange}
      onChange={(value) => {
        onDateRangeSelect(flatDateRanges.find((item) => item.value === value)!)
      }}
      rounding={rounding}
      appearance="square"
      theme="chip"
      size={size}
      triggerContent={<Icon name="calendar" />}
      rightContent={false}
      active={selectedDateRange ? true : undefined}
      data-testid={`chart-${chartIndex}-date-range-select`}
    >
      {dateRangeGroups.flatMap(({ label, dateRanges }, index) => (
        <MenuGroup key={`${label}-${index}`} label={label}>
          {dateRanges.map(({ href, value, label, withReload, timeframe }) => {
            let linkProps = {}
            if (href) {
              linkProps = {
                as: Link,
                to: href,
                reloadDocument: withReload ?? !FinvizSettings.hasUserPremium,
              }
            }
            return (
              <SelectOption
                {...linkProps}
                key={value}
                data-testid={`chart-${chartIndex}-date-range-select-${value}`}
                className="min-w-32"
                rounding={rounding}
                value={value}
                onClick={() => {
                  if (!timeframe) onCustomDateRangeClick?.()
                }}
              >
                {label}
              </SelectOption>
            )
          })}
        </MenuGroup>
      ))}
    </Select>
  )
}
