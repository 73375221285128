import classNames from 'classnames'
import * as React from 'react'

import {
  BUTTON_DEFAULT_ELEMENT,
  Button,
  ButtonComponentProps,
  ButtonPadding,
  ButtonProps,
  ButtonThemeType,
} from './button'
import { Icon, isValidIconName } from './icon'

export interface OptionButtonProps extends Omit<ButtonProps, 'theme'> {
  /**
   * Description for the option
   */
  subtitle?: React.ReactNode

  /**
   * Set the button theme when it is selected
   */
  activeTheme?: ButtonThemeType

  /**
   * Set the base button theme when not selected
   */
  theme?: ButtonThemeType | ((isHovered: boolean) => ButtonThemeType)
}

function getDefaultTheme(isHovered: boolean) {
  return isHovered ? 'menuItem' : 'menuItemTransparent'
}

export const OptionButton = React.forwardRef(function OptionButton<
  TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT,
>(
  {
    subtitle,
    children,
    'aria-selected': ariaSelected,
    'data-active-item': dataActiveItem,
    rounding = 'small',
    activeTheme = 'blue',
    theme = getDefaultTheme,
    ...props
  }: Omit<ButtonComponentProps<TagType>, 'theme'> & OptionButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const isSelected = ariaSelected === true
  const isHovered = dataActiveItem === true
  const defaultTheme = typeof theme === 'function' ? theme(isHovered) : theme

  const iconTheme = classNames({
    'text-gray-500 dark:text-gray-400': !isSelected && !isHovered,
    'text-white dark:text-default': isSelected,
    'text-default': !isSelected && isHovered,
  })

  return (
    <Button
      // TS thinks the Omit type (due to spread above) doesn’t satisfy button props
      {...(props as ButtonComponentProps<TagType>)}
      ref={ref}
      align="left"
      className={classNames(props.className, ButtonPadding.regular, 'select-none', {
        'text-2xs': !!subtitle,
      })}
      contentClass={classNames(props.contentClass, 'font-normal')}
      rounding={rounding}
      active={props.active ?? (isHovered && !isSelected)}
      theme={isSelected ? activeTheme : defaultTheme}
      size={props.size ?? (subtitle ? 'none' : props.size)}
      aria-selected={ariaSelected}
      data-active-item={dataActiveItem}
      leftContent={
        isValidIconName(props.leftContent) ? <Icon name={props.leftContent} className={iconTheme} /> : props.leftContent
      }
      rightContent={
        isValidIconName(props.rightContent) ? (
          <Icon name={props.rightContent} className={iconTheme} />
        ) : (
          props.rightContent
        )
      }
    >
      {children}
      {subtitle && (
        <div
          className={classNames('-mt-1 w-full whitespace-normal text-3xs', {
            'text-muted-2': !isSelected,
          })}
        >
          {subtitle}
        </div>
      )}
    </Button>
  )
})
