import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { PopoverPlacement } from '../popover'
import { SelectButtonProps } from './SelectButton'
import { SelectItems } from './SelectItems'
import { SelectList, SelectListProps } from './SelectList'
import { SelectTooltipWrapper } from './SelectTooltipWrapper'
import { SelectItem, SelectItemGroup } from './types'
import { isSelectItem } from './utils'

export interface DesktopSelectProps<ValueType, ItemType, Name = never>
  extends Omit<
      SelectButtonProps,
      // Fix clash with `button` element html props
      'value' | 'defaultValue' | 'onChange' | 'label' | 'name' | 'valueOverride'
    >,
    Pick<SelectListProps, 'gutter'> {
  /**
   * Name of the select which coudl be used to distinguish onChange events.
   * Passed as 2nd argument to `onChange`
   */
  name?: Name

  /**
   * Set value for the select
   */
  value?: ValueType

  /**
   * Set default value for an uncontrolled select
   */
  defaultValue?: ValueType

  /**
   * Label to display above the select
   */
  label?: React.ReactNode

  /**
   * Callback when item changes. Called for both controlled and uncontrolled selects
   */
  onChange?: (
    item: ItemType extends SelectItemGroup<any>
      ? ValueType extends SelectItem<any>
        ? ValueType
        : SelectItem<ValueType>
      : ItemType,
    name: Name
  ) => void

  /**
   * Options to select from. When defined, the options are constructed automatically
   * so you don’t have to pass children to the select.
   *
   * When `nativeSelect` is set to "mobile", children take precedence on desktop
   */
  items?: ItemType[]

  /**
   * Enable virtualization of select items to improve performance of long lists
   *
   * @default items.length > 50
   */
  virtualized?: boolean

  /**
   * Custom button renderer for the select
   */
  trigger?: JSX.Element

  /**
   * Text for the button (usually the selected value). Falls back to placeholder if not set
   */
  triggerContent?: React.ReactNode

  /**
   * Show tooltip on the select
   */
  tooltip?: React.ReactNode

  /**
   * Popover placement
   *
   * @default "bottom-start"
   */
  placement?: PopoverPlacement

  /**
   * Change the direction of the items placement in popover
   *
   * @default vertical
   */
  orientation?: 'vertical' | 'horizontal'

  /**
   * Set whether or not the Select is interactive to save resources. If not interactive,
   * the select only renders a placeholder button
   *
   * @default true
   */
  isInteractive?: boolean

  /**
   * Callback when the select opens or closes
   */
  onInteractionChange?: (open: boolean) => void

  /**
   * ### Only applicable for desktop selects without `children`, when passing `items`.
   * ### If you want to pass `children`, use the `onSearch` prop instead.
   *
   * Specifies whether or not to show a search input inside of the popover. Number
   * specifies the >= number of items after which search will be visible.
   * Search is made for both value and label props
   *
   * @default 10
   */
  comboBox?: boolean | number

  /**
   * ### Only applicable for desktop selects with `children`.
   * ### Use `comboBox` if you have `items` prop
   * When defined, the select is considered being a combobox and will display
   * input for filtering items. The filtering is up to the user.
   */
  onSearch?: (value: string) => void

  /**
   * When combined with `items` the children will automatically have testid assigned.
   * Otherwise just passed to the trigger button
   */
  'data-testid'?: string

  listProps?: SelectListProps
}

function getCollectionItem(item: SelectItem<unknown>, index: number) {
  return {
    id: `item-${index}-${String(item.value).replace(/\W/g, '')}`,
    label: item.label,
    value: item,
    children: item.label,
  }
}

export function DesktopSelect<
  ValueType,
  ItemType extends SelectItem<ValueType> | SelectItemGroup<ValueType>,
  Name = never,
>({
  name,
  label,
  value,
  defaultValue,
  onChange,
  items,
  virtualized = (items?.length ?? 0) > 50,
  trigger,
  tooltip,
  onInteractionChange,
  comboBox,
  onSearch,
  children,
  gutter,
  placement = 'bottom-start',
  orientation,
  listProps,
  ...props
}: React.PropsWithChildren<DesktopSelectProps<ValueType, ItemType, Name>>) {
  // Used when `items` are passed to construct a desktop select with comboBox
  const [internalSearch, setInternalSearch] = React.useState('')

  // Setup combobox if enabled
  const isComboBox =
    !!onSearch || comboBox === true || (typeof comboBox === 'number' && !!items && items.length > comboBox)
  const SelectWrapper = isComboBox ? Ariakit.ComboboxProvider : React.Fragment
  const wrapperProps = React.useMemo(
    () =>
      isComboBox ? { resetValueOnHide: true, includesBaseElement: false, setValue: onSearch ?? setInternalSearch } : {},
    [isComboBox, onSearch]
  )

  // Handle tooltip prop
  const ButtonWrapper = tooltip ? SelectTooltipWrapper : React.Fragment
  const buttonWrapperProps = React.useMemo(() => (tooltip ? { content: tooltip } : {}), [tooltip])

  if (isComboBox && !!children && !onSearch) {
    console.warn(
      'ComboBoxes with children options require `onSearch` prop, otherwise searching will not work. \n Either provide `onSearch` or disable this behavior by passing `comboBox={false}`'
    )
  }

  const listItems = React.useMemo(
    () =>
      items?.map((item, index) =>
        isSelectItem(item) ? getCollectionItem(item, index) : { ...item, items: item.items.map(getCollectionItem) }
      ) as ItemType[],
    [items]
  )

  return (
    <SelectWrapper {...wrapperProps}>
      {/* @ts-expect-error - The types are wrong here, the select allows any values, not just strings or ints */}
      <Ariakit.SelectProvider<ValueType>
        // Loop to the first item after pressing arrow down on last item
        focusLoop
        virtualFocus
        value={value}
        defaultValue={defaultValue ?? ''}
        // Item will always be of ItemType
        setValue={(item) => setTimeout(() => onChange?.(item as any, name!), 50)}
        setOpen={onInteractionChange}
        placement={placement}
        orientation={orientation}
        defaultItems={listItems}
      >
        {label && <Ariakit.SelectLabel>{label}</Ariakit.SelectLabel>}

        <ButtonWrapper {...buttonWrapperProps}>
          <Ariakit.Select render={trigger} />
        </ButtonWrapper>

        <SelectList
          {...listProps}
          isComboBox={isComboBox}
          // Make sure the rounding is off when trigger rounding is off
          rounding={props.rounding === 'none' ? 'none' : undefined}
          gutter={gutter}
          unmountOnHide={listProps?.unmountOnHide ?? virtualized}
          hasItemChildren={!!items}
          orientation={orientation}
          parentTestId={props['data-testid']}
        >
          {/* Either construct options from children or provided items prop */}
          {children ?? (
            <SelectItems
              items={items}
              rounding={props.rounding === 'none' ? 'none' : undefined}
              searchValue={internalSearch}
              parentTestId={props['data-testid']}
              virtualized={virtualized}
            />
          )}
        </SelectList>
      </Ariakit.SelectProvider>
    </SelectWrapper>
  )
}
