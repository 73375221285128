import { getSanitizedTicker } from '../../../../app/shared/ticker-sanitizer'
import { ContentType, apiRequest } from '../../../services/api'
import { PORTFOLIO_CASH_SYMBOL } from '../constants/general'
import {
  PortfolioDefinition,
  PortfolioEntryBase,
  PortfolioFormGroup,
  PortfolioInitData,
  PortfolioSymbolType,
  PortfolioTransaction,
  PortfolioTransactionWatch,
} from '../types'
import { getSymbolType } from '../utils'

export const EMPTY_RESPONSE: PortfolioInitData = {
  limit: 0,
  selectedPortfolio: 0,
  limitTransactions: 0,
  portfolio: [],
  news: [],
  portfolios: [],
}

export function portfolioData(portfolioId?: string | number | null) {
  return apiRequest<PortfolioInitData>('/api/portfolio_data.ashx', { query: { pid: portfolioId ?? '' } })
}

export function deletePortfolio(portfolioId?: string | number) {
  return apiRequest('/request_portfolio_delete.ashx', { query: { pid: portfolioId }, method: 'DELETE' })
}

export function portfolioEntry(
  query?: Record<string, any>,
  method?: string,
  body?: Omit<Partial<PortfolioEntryBase>, 'type'>
) {
  return apiRequest(
    '/api/portfolio_entry.ashx',
    {
      query,
      method,
      body: JSON.stringify(body),
    },
    true
  )
}

export const QUOTE_INVALID_TICKER_REQUEST = '_UNKNOWN'
export const QUOTE_INVALID_TICKER_RESPONSE = 'NA'
export const QUOTE_TICKER_DELIMITER = ','

export function quoteData(tickers: string[]) {
  return apiRequest<string>('request_quote.ashx', {
    query: { t: tickers.filter((ticker) => ticker.length).join(',') },
    headers: { 'Content-Type': ContentType.Html },
  })
}

export async function validateTickers(tickers: string[]) {
  const response = await apiRequest<{ tickers: string; specialSymbols: Record<string, PortfolioSymbolType> }>(
    '/portfolio_tickers.ashx',
    {
      query: { json: true },
    },
    true
  )

  const parsedTickers = response?.tickers.split('|') ?? []
  const specialTickers = Object.keys(response?.specialSymbols ?? [])

  return tickers.map((ticker) => [...specialTickers, ...parsedTickers].includes(ticker))
}

export async function recalculatePortfolio(
  rows: PortfolioFormGroup[],
  totalValue = 100000.0
): Promise<PortfolioFormGroup[]> {
  const tickers: string[] = []
  let numberOfTransactions = 0

  rows.forEach((row) => {
    const ticker = getSanitizedTicker(row.ticker.trim(), false, [PORTFOLIO_CASH_SYMBOL])

    if (getSymbolType(ticker) === PortfolioSymbolType.Stock) numberOfTransactions += row.transactions.length
    tickers.push(ticker.length ? ticker : QUOTE_INVALID_TICKER_REQUEST)
  })

  const pricePerColumn = totalValue / numberOfTransactions

  const response = await quoteData(tickers)
  const prices = response?.split(QUOTE_TICKER_DELIMITER)

  if (prices?.length !== tickers.length) {
    window.Sentry?.captureMessage('Invalid recalculated data', {
      extra: {
        requestTickers: tickers,
        requestTickersLength: tickers.length,
        responseLength: prices?.length,
      },
    })

    return []
  }

  const tickerPrices = Object.fromEntries(
    prices.map((price, index) => {
      const tickerPrice = price === QUOTE_INVALID_TICKER_RESPONSE ? NaN : parseFloat(price)

      return [tickers[index], isNaN(tickerPrice) ? NaN : tickerPrice]
    })
  )

  return rows.map((row) => ({
    id: row.id,
    ticker: row.ticker,
    transactions: row.transactions.map((transaction) => {
      const price = tickerPrices[row.ticker]
      const shares = Math.round((pricePerColumn / price) * 10000) / 10000

      const calculatedPrice = isNaN(price) ? transaction.price : price
      const calculatedShares = isNaN(shares) ? transaction.shares : shares

      return {
        ...transaction,
        transaction:
          transaction.transaction === PortfolioTransaction.Watch ? PortfolioTransaction.Buy : transaction.transaction,
        price: transaction.type === PortfolioSymbolType.Cash ? 0 : calculatedPrice,
        shares: transaction.type === PortfolioSymbolType.Cash ? 0 : calculatedShares,
      }
    }),
  }))
}

export function submitForm(data: URLSearchParams) {
  return apiRequest('/portfolio_submit.ashx', {
    method: 'POST',
    body: data.toString(),
    headers: { 'Content-Type': ContentType.FormUrlEncoded },
  })
}

export type TickersApiResponse = {
  portfolioId: number
  message?: string
  portfolioEntries: Record<number, Partial<PortfolioTransactionWatch>>
}

export enum ErrorSource {
  Portfolio,
  Tickers,
}

export type TickersApiErrorResponse = {
  source: ErrorSource
  message: string
  isNotification?: boolean
}

export function portfolioTickers({
  tickers,
  portfolioName,
  portfolioId,
}: {
  tickers: string
  portfolioName?: string
  portfolioId?: number
}) {
  const query = Object.assign(
    { tickers },
    typeof portfolioName !== 'undefined' && { portfolio_name: portfolioName },
    typeof portfolioId !== 'undefined' && { pid: portfolioId }
  )

  return apiRequest<TickersApiResponse>(
    '/api/portfolio_tickers.ashx',
    {
      method: 'POST',
      query,
    },
    true
  )
}

export function submitEditForm(data: string | URLSearchParams) {
  return apiRequest('/portfolio_submit.ashx', {
    method: 'POST',
    body: data.toString(),
    headers: { 'Content-Type': ContentType.FormUrlEncoded },
  })
}

export function reorderPortfolios(body: PortfolioDefinition[]) {
  return apiRequest('/api/portfolio_reorder.ashx', {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}
