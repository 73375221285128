import * as Ariakit from '@ariakit/react'
import classNames from 'classnames'
import * as React from 'react'

import { Button, ButtonHTMLProps, ButtonProps, ButtonSize } from '../button'
import { Delayed } from '../delayed'
import { Icon, IconNameType, isValidIconName } from '../icon'
import { Spinner } from '../spinner'
import { SelectItem } from './types'
import { isSelectItem } from './utils'

type ValueType = SelectItem<unknown> | string

const DEFAULT_RIGHT_ICON = 'caretDown'

export interface SelectButtonProps extends Omit<ButtonHTMLProps, 'value'>, Omit<ButtonProps, 'rightContent'> {
  /**
   * Show a spinner in place of the button caret. The spinner is wrapped in Delayed
   * and uses the default timeout
   */
  isLoading?: boolean

  /**
   * Placeholder when no value is selected
   */
  placeholder?: string

  /**
   * Override for the select value from context. Used to provide value for native
   * select trigger
   */
  valueOverride?: ValueType

  rightContent?: boolean | IconNameType | JSX.Element
}

export const SelectButton = React.forwardRef<HTMLElement, React.PropsWithChildren<SelectButtonProps>>(
  function SelectButton(
    {
      isLoading,
      placeholder = 'Select value',
      children,
      valueOverride,
      theme = 'outline',
      rightContent = DEFAULT_RIGHT_ICON,
      ...props
    },
    ref
  ) {
    const context = Ariakit.useSelectContext()
    const contextValue = context?.useState('value') as ValueType
    const isOpen = context?.useState('open')
    const selectedValue = valueOverride ?? contextValue

    let buttonText = placeholder
    if (isSelectItem(selectedValue)) buttonText = selectedValue.label ?? selectedValue.value
    else if (typeof selectedValue === 'string' && selectedValue.length) buttonText = selectedValue
    else if (selectedValue) buttonText = String(selectedValue)

    const rightIcon =
      isValidIconName(rightContent) || rightContent === true ? (
        <Icon
          name={rightContent === true ? DEFAULT_RIGHT_ICON : rightContent}
          className={classNames('shrink-0', {
            'text-gray-300 group-hover/select:text-gray-400 group-aria-expanded/select:text-gray-400 dark:text-gray-400 dark:group-hover/select:text-gray-300 dark:group-aria-expanded/select:text-gray-300':
              theme === 'outline',
          })}
        />
      ) : React.isValidElement(rightContent) ? (
        rightContent
      ) : undefined

    return (
      <Button
        {...props}
        ref={ref}
        type="button"
        theme={theme}
        active={props.active ?? isOpen}
        className={classNames(props.className, 'group/select select-none')}
        contentClass={classNames(props.contentClass, 'truncate', {
          'font-normal': theme === 'outline',
        })}
        rightContent={
          isLoading ? (
            <Delayed delayComponent={rightIcon}>
              <Spinner width={props.size && ButtonSize[props.size] === ButtonSize.xxsmall ? 12 : 16} />
            </Delayed>
          ) : (
            rightIcon
          )
        }
      >
        {children ?? buttonText}
      </Button>
    )
  }
)
