import * as dateFns from 'date-fns'
import * as React from 'react'

import { parseInputAsDate } from '../../modules/portfolio/utils'
import { StatelessDatePicker } from '../date-picker'
import { InputComponentProps } from '../input'
import { usePopoverState } from '../popover'
import { Tooltip, TooltipTrigger, useTooltipState } from '../tooltip'
import { useDateInputWithPickerValue } from './useDateInputWithPickerValue'

const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy'

interface Props {
  /**
   * Date input element / calendar popover trigger
   */
  trigger: React.ReactElement<InputComponentProps>

  /**
   * Date string format which is used for input element (trigger) value
   */
  viewDateFormat?: string

  /**
   * Date string format which is used for inputValue value and onChange callback value
   */
  dataDateFormat?: string

  /**
   * Min date for datepicker
   */
  minDate?: Date

  /**
   * Date input value in dataDateFormat
   */
  inputValue: string | undefined

  /**
   * Change callback with value in dataDateFormat if in correct dataDateFormat
   * or with string value which is provided in the input if it's invalid date
   */
  onChange: (value: string) => void

  /**
   * When true, error style is applied on ticker and error popover shows on focus
   */
  hasError: boolean

  /**
   * Callback if error state changes
   */
  onErrorChange: (hasError: boolean) => void

  /**
   * Callback which triggers if error tooltip or calendar popover opens/closes
   */
  onIsInteractingChange?: (isInteracting: boolean) => void
}

export function DateInputWithPicker({
  trigger,
  viewDateFormat = DEFAULT_DATE_FORMAT,
  dataDateFormat = DEFAULT_DATE_FORMAT,
  minDate,
  inputValue = '',
  onChange,
  hasError,
  onErrorChange,
  onIsInteractingChange,
}: Props) {
  const pickerState = usePopoverState()
  const isPickerMounted = pickerState.useState('mounted')
  const tooltipState = useTooltipState({ placement: 'top' })
  const isTooltipMounted = tooltipState.useState('mounted')
  const WrapperComponent = hasError ? TooltipTrigger : React.Fragment
  const wrapperProps = hasError
    ? ({
        state: tooltipState,
        tabIndex: -1,
        hideOnBlur: true,
        showOnHover: false,
      } as React.ComponentProps<typeof TooltipTrigger>)
    : {}

  const isInteractingWithDateInput = isPickerMounted || isTooltipMounted
  const value = useDateInputWithPickerValue({
    inputValue,
    dataDateFormat,
    viewDateFormat,
  })

  React.useEffect(() => {
    onIsInteractingChange?.(isInteractingWithDateInput)
    // We don't need to pass entire props as we check only if onIsInteractingChange changed
  }, [onIsInteractingChange, isInteractingWithDateInput])

  /**
   * Handle date changes
   */
  const onDateChange = React.useCallback(
    (dateValue: Date | string) => {
      // Convert input to correct format if valid
      const parsedDate = parseInputAsDate({ input: dateValue, format: viewDateFormat })

      if (typeof dateValue === 'string' && !dateFns.isValid(parsedDate)) {
        onChange(dateValue)
        return
      }

      const date = dateFns.format(parsedDate, dataDateFormat)
      onErrorChange(false)
      onChange(date)
      tooltipState.hide()
    },
    [tooltipState, onErrorChange, onChange, dataDateFormat, viewDateFormat]
  )

  /**
   * Reset error state on blur when editing manually
   */
  const handleBlur = React.useCallback(() => {
    tooltipState.hide()
    const date = parseInputAsDate({ input: value.input ?? '', format: viewDateFormat })

    if (dateFns.isValid(date)) {
      onErrorChange(false)
    }
  }, [onErrorChange, value.input, viewDateFormat, tooltipState])

  return (
    <WrapperComponent {...(wrapperProps as any)}>
      <StatelessDatePicker state={pickerState} value={value.picker} onChange={onDateChange} minDate={minDate}>
        {React.cloneElement<InputComponentProps>(trigger, {
          onChange: (ev) => onDateChange(ev.currentTarget.value),
          onKeyDown: () => {
            setTimeout(() => {
              pickerState.hide()
            })
          },
          onBlur: handleBlur,
          onFocus: hasError ? () => tooltipState.show() : undefined,
        })}
      </StatelessDatePicker>
      <Tooltip hideOnHoverOutside={false} state={tooltipState} color="red" className="max-w-48" gutter={4}>
        Invalid date, please enter date in {viewDateFormat.toUpperCase()} format.
      </Tooltip>
    </WrapperComponent>
  )
}
