import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { Button } from '../button'
import { Icon } from '../icon'
import { Heading } from '../typography'
import { DialogDragContext } from './hooks'

interface DialogHeaderProps {
  /**
   * Hide the close button
   * @default true
   */
  isCloseButtonVisible?: boolean

  /**
   * Callback when user clicks on close button. The button will be hidden if not set
   */
  onCloseClick?: () => void

  /**
   * Additional class names for the component
   */
  className?: string

  /**
   * Whether or not the dialog header will have default horizontal padding
   *
   * @default true
   */
  hasHorizontalPadding?: boolean

  /**
   * Whether or not the dialog header will have default min height
   *
   * @default true
   */
  hasMinHeight?: boolean

  /**
   * Whether or not the dialog header children should be rendered as Heading
   *
   * @default true
   */
  asHeading?: boolean
}

/**
 * Component which renders title and a close button
 */
export function DialogHeader({
  children,
  className,
  hasHorizontalPadding = true,
  hasMinHeight = true,
  asHeading = true,
  isCloseButtonVisible = true,
  onCloseClick,
}: React.PropsWithChildren<DialogHeaderProps>) {
  const dialogDrag = React.useContext(DialogDragContext)

  return (
    <div
      ref={dialogDrag.handleRef}
      data-testid="dialog-header"
      className={classnames(
        className,
        'flex shrink-0 select-none items-center justify-between border-b border-gray-100 dark:border-gray-600',
        {
          'cursor-grab active:cursor-grabbing': dialogDrag.enabled,
          'min-h-12': hasMinHeight,
          'px-4': hasHorizontalPadding,
        }
      )}
    >
      {children &&
        (asHeading ? (
          <Heading level={4} className="my-0">
            {children}
          </Heading>
        ) : (
          children
        ))}
      {isCloseButtonVisible && (
        <Button
          as={Ariakit.DialogDismiss}
          theme="opacity"
          appearance="square"
          data-testid="dialog-button-close"
          onClick={() => onCloseClick?.()}
        >
          <Icon name="close" width={24} />
        </Button>
      )}
    </div>
  )
}
