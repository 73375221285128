import classnames from 'classnames'
import * as React from 'react'

export const Table = React.forwardRef(
  (props: React.PropsWithChildren<React.HTMLProps<HTMLTableElement>>, ref: React.ForwardedRef<HTMLTableElement>) => (
    <table
      {...props}
      ref={ref}
      cellPadding={props.cellPadding ?? 0}
      className={classnames(
        props.className,
        'w-full border-separate border-spacing-0 font-sans text-2xs tabular-nums text-gray-800 dark:text-gray-100'
      )}
    />
  )
)

export function TableHead(props: React.PropsWithChildren<React.HTMLProps<HTMLTableSectionElement>>) {
  return (
    <thead {...props}>
      {/* sticky column uses bg-inherit which we want to propagate from thead */}
      <tr className="bg-inherit">{props.children}</tr>
    </thead>
  )
}

interface TableBodyProps extends React.HTMLProps<HTMLTableSectionElement> {
  spacing?: 'regular' | 'large' | 'none'
}

export const TableBody = React.forwardRef(
  (
    { spacing = 'none', ...props }: React.PropsWithChildren<TableBodyProps>,
    ref: React.ForwardedRef<HTMLTableSectionElement>
  ) => (
    <tbody
      {...props}
      ref={ref}
      className={classnames(props.className, {
        'after:block after:w-full': spacing !== 'none',
        'after:h-0.5': spacing === 'regular',
        'after:h-1': spacing === 'large',
      })}
    />
  )
)

export function TableFooter(props: React.PropsWithChildren<React.HTMLProps<HTMLTableSectionElement>>) {
  return (
    <tfoot {...props}>
      <tr>{props.children}</tr>
    </tfoot>
  )
}
