import * as dateFns from 'date-fns'

import { PortfolioEntryTransaction, PortfolioSymbolType, PortfolioTransaction } from '../types'

export const PORTFOLIO_CASH_SYMBOL = '$CASH'

export const PORTFOLIO_DATE_FORMAT_VIEW = 'MM-dd-yyyy'
export const PORTFOLIO_DATE_FORMAT_DATA = "yyyy-MM-dd'T00:00:00'"

export const PORTFOLIO_REFRESH_INTERVAL_ELITE = 20 * 1000
export const PORTFOLIO_REFRESH_INTERVAL_FREE = 60 * 1000

export const TRANSACTION_LABEL: Record<PortfolioTransaction, string> = {
  [PortfolioTransaction.Watch]: 'Watch',

  // Stock transactions
  [PortfolioTransaction.Buy]: 'Buy',
  [PortfolioTransaction.Short]: 'Short Sell',

  // Cash transactions
  [PortfolioTransaction.CashDeposit]: 'Cash Deposit',
  [PortfolioTransaction.CashWithdraw]: 'Cash Withdraw',
  [PortfolioTransaction.CashIncome]: 'Income',
  [PortfolioTransaction.CashFee]: 'Fee',
}

export const STOCK_TRANSACTIONS = [PortfolioTransaction.Buy, PortfolioTransaction.Short]

export const CASH_TRANSACTIONS = [
  PortfolioTransaction.CashDeposit,
  PortfolioTransaction.CashWithdraw,
  PortfolioTransaction.CashIncome,
  PortfolioTransaction.CashFee,
]

export const EMPTY_FORM_ENTRY = {
  ticker: '',
  date: `${dateFns.format(new Date(), PORTFOLIO_DATE_FORMAT_DATA)}`,
  shares: undefined,
  price: undefined,
  transaction: PortfolioTransaction.Buy,
}

export const EMPTY_TRANSACTION: Omit<PortfolioEntryTransaction, 'id'> = {
  ...EMPTY_FORM_ENTRY,
  shares: 0,
  price: 0,
  type: PortfolioSymbolType.Stock,
  valuePaid: null,
  valueMarket: null,
  valueMarketPrev: null,
  gainMarket: null,
  gainMarketPct: null,
  gainMarketUsd: null,
  gainTodayUsd: null,
  lastChangePct: null,
}

export const PORTFOLIO_INPUT_NAME = 'portfolio_name'
export const PORTFOLIO_INPUT_EMPTY_ERROR = 'Please enter portfolio name.'
export const PORTFOLIO_INPUT_EXISTS_ERROR = 'Portfolio with that name already exists.'
export const TICKERS_INPUT_NAME = 'tickers'
export const TICKERS_INPUT_FORMAT_TIP = 'Separate multiple tickers by a comma, e.g.: AAPL, MSFT, GOOG.'

// Keep in sync with PortfolioSizeEnum in Website/App_Code/User.cs & config/types/globals.d.ts
export enum PortfolioSizeEnum {
  Default = 'default',
  Compact = 'compact',
}
