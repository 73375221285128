export type MapOrder = Record<string, number>

export const MAP_MIN_WIDTH = 700
export const MAP_MIN_HEIGHT = 388
export const SECTOR_MIN_HEIGHT = 5
export const LEGEND_HEIGHT = 79

export const LARGE_MAP_HEIGHT_RATIO = 1.8
export const SMALL_MAP_GEO_HEIGHT_RATIO = 1.7
export const SMALL_MAP_HEIGHT_RATIO = 1.085 // old design ratio - 1.33

// Minimal size of node to display header
export const LARGE_NODE_SIZE = {
  dx: 40,
  dy: 12,
}

// Don't display header if node height - header is smaller than this
export const LARGE_NODE_MIN_HEIGHT = 5

export const ORDER_SP500: MapOrder = {
  Technology: 10,
  Financial: 11,
  'Communication Services': 7,
  'Consumer Defensive': 8,
  'Consumer Cyclical': 9,
  Healthcare: 6,
  Industrials: 5,
  'Real Estate': 4,
  Energy: 3,
  Utilities: 2,
  'Basic Materials': 1,

  // Industries
  'Software - Infrastructure': 2,
  Semiconductors: 1,
}

export const ORDER_SP500_SMALL: MapOrder = {
  ...ORDER_SP500,
  'Communication Services': 9,
  'Consumer Defensive': 8,
  'Consumer Cyclical': 7,
}

export const ORDER_SEC_ALL: MapOrder = {
  Financial: 10,
  Technology: 9,
  'Communication Services': 8,
  'Consumer Cyclical': 6,
  'Consumer Defensive': 5,
  Healthcare: 7,
  Industrials: 4,
  'Real Estate': 3,
  Energy: 2,
  Utilities: 1,
  'Basic Materials': 0,

  // Industries
  'Software - Infrastructure': 2,
  Semiconductors: 1,
}
export const ORDER_ETF: MapOrder = {
  US: 11,
  'US Sector': 10,
  'US Large Cap': 9,
  'US Mid Cap': 8,
  'US Small Cap': 7,
  'US Volatility': 6,
  Global: 5,
  International: 3,
  'Fixed Income': 2,
  Commodity: 4,
  Strategy: 1,
  Forex: 0,
}

export const ORDER_SCREENER: MapOrder = {
  Technology: 10,
  Financial: 9,
  'Communication Services': 8,
  Healthcare: 7,
  'Consumer Cyclical': 6,
  'Consumer Defensive': 5,
  Industrials: 4,
  'Real Estate': 3,
  Energy: 2,
  Utilities: 1,
  'Basic Materials': 0,
}

export const ORDER_WORLD: MapOrder = {
  Ireland: 20,
  'United Kingdom': 19,
  Spain: 18,
  France: 17,
  Belgium: 16,
  Netherlands: 15,
  Switzerland: 14,

  China: 21,
  Japan: 20,
  India: 19,
  'Hong Kong': 18,
  'South Korea': 15,
  Taiwan: 14,

  Colombia: 30,
  Chile: 29,
  Argentina: 28,
}

export const WORLD_INDUSTRIES = [
  {
    x: 4,
    y: 0,
    dx: 240,
    dy: 226,
    name: 'Canada',
    countries: ['Canada'],
  },
  {
    x: 4,
    y: 230,
    dx: 240,
    dy: 135,
    name: 'Mexico',
    countries: ['Cayman Islands', 'Bermuda', 'Mexico'],
  },
  {
    x: 4,
    y: 375,
    dx: 240,
    dy: 170,
    name: 'South America',
    countries: ['Netherlands Antilles', 'Panama', 'Peru', 'Argentina', 'Colombia', 'Chile', 'Brazil'],
  },
  {
    x: 730,
    y: 380,
    dx: 108,
    dy: 80,
    name: 'South Africa',
    countries: ['South Africa'],
  },
  {
    x: 725,
    y: 180,
    dx: 108,
    dy: 81,
    name: 'Israel',
    countries: ['Israel'],
  },
  {
    x: 1055,
    y: 420,
    dx: 144,
    dy: 80,
    name: 'Australia',
    countries: ['Australia'],
  },
  {
    x: 871,
    y: 0,
    dx: 336,
    dy: 396,
    name: 'Asia',
    countries: [
      'Malaysia',
      'Russia',
      'Philippines',
      'Indonesia',
      'Singapore',
      'South Korea',
      'Taiwan',
      'India',
      'Hong Kong',
      'Japan',
      'China',
    ],
  },
  {
    x: 324,
    y: 0,
    dx: 374,
    dy: 428,
    name: 'Europe',
    countries: [
      'Cyprus',
      'Channel Islands',
      'Greece',
      'Luxembourg',
      'Germany',
      'Belgium',
      'Spain',
      'France',
      'Ireland',
      'Switzerland',
      'Netherlands',
      'United Kingdom',
    ],
  },
  {
    x: 697,
    y: 75,
    dx: 144,
    dy: 51,
    name: 'Frozen',
    countries: ['Finland', 'Sweden', 'Norway', 'Denmark'],
  },
  {
    x: 542,
    y: 414,
    dx: 102,
    dy: 68,
    name: 'Italy',
    countries: ['Italy'],
  },
]
