/**
 * Ignore any subsequent callback calls until the previous callback has finished
 */

export function waitForPreviousPromise(callback: () => void | Promise<void>) {
  let callbackPromise: Promise<void> | void | null = null

  return async function () {
    if (callbackPromise !== null) return

    callbackPromise = callback()
    await callbackPromise
    callbackPromise = null
  }
}
