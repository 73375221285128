import * as React from 'react'

import { TIMEFRAME } from '../../constants'
import { useSyncLocalStorage } from '../../hooks/use-sync-local-storage'

type FavoriteTimeframesMap = { [K in TIMEFRAME | 'intraday']?: boolean }

export const TIMEFRAME_FAVORITE_DEFAULTS = {
  [TIMEFRAME.i1]: true,
  [TIMEFRAME.i2]: false,
  [TIMEFRAME.i3]: true,
  [TIMEFRAME.i5]: true,
  [TIMEFRAME.i10]: false,
  [TIMEFRAME.i15]: true,
  [TIMEFRAME.i30]: true,
  [TIMEFRAME.h]: true,
  [TIMEFRAME.h2]: false,
  [TIMEFRAME.h4]: false,
  [TIMEFRAME.d]: true,
  [TIMEFRAME.w]: true,
  [TIMEFRAME.m]: true,
}

export const TIMEFRAME_FAVORITE_DEFAULTS_FREE = {
  ...TIMEFRAME_FAVORITE_DEFAULTS,
  intraday: true,
}

const LOCAL_STORAGE_KEY = 'favoriteTimeframesMap'

export function useFavoriteTimeframes(isPremium = true) {
  const { state, setState } = useSyncLocalStorage<FavoriteTimeframesMap>(
    LOCAL_STORAGE_KEY,
    isPremium ? TIMEFRAME_FAVORITE_DEFAULTS : TIMEFRAME_FAVORITE_DEFAULTS_FREE
  )

  const toggleFavoriteTimeframe = React.useCallback(
    (timeframe: TIMEFRAME | 'intraday') => {
      setState((prevState) => ({
        ...prevState,
        [timeframe]: !prevState[timeframe],
      }))
    },
    [setState]
  )

  const favoriteTimeframes = React.useMemo(
    () => Object.keys(state).filter((key) => state[key as keyof FavoriteTimeframesMap]),
    [state]
  )

  return {
    favoriteTimeframes: isPremium ? favoriteTimeframes : Object.keys(TIMEFRAME_FAVORITE_DEFAULTS_FREE),
    toggleFavoriteTimeframe,
  }
}
