// Copy if this file is in charts repo in app/utils/ folder
import * as React from 'react'

export function useIsMounted() {
  const isMountedRef = React.useRef(false)
  const getIsMounted = React.useCallback(() => isMountedRef.current, [])

  React.useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return getIsMounted
}
