import classnames from 'classnames'
import * as React from 'react'

interface TableRowProps extends React.PropsWithChildren<React.HTMLProps<HTMLTableRowElement>> {
  isFirst?: boolean
  isLast?: boolean
  isHoverable?: boolean
  isRounded?: boolean
}

export const TableRow = React.forwardRef(
  (
    { isFirst, isLast, isHoverable = true, isRounded = false, ...props }: TableRowProps,
    ref: React.ForwardedRef<HTMLTableRowElement>
  ) => (
    <tr
      {...props}
      ref={ref}
      className={classnames(props.className, 'group', {
        'is-first-row': isFirst,
        'is-last-row': isLast,
        'is-hoverable': isHoverable,
        'is-rounded': isRounded,
      })}
    />
  )
)
