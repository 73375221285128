import * as Ariakit from '@ariakit/react'

export type PopoverStateReturn = ReturnType<typeof usePopoverState>

/**
 * Hook which provides state to dialogs
 */
export function usePopoverState({ placement = 'bottom-start', ...settings }: Ariakit.PopoverStoreProps = {}) {
  return Ariakit.usePopoverStore({ placement, ...settings })
}
