import * as dateFns from 'date-fns'
import * as React from 'react'

import { parseInputAsDate } from '../../modules/portfolio/utils'
import { DateInputWithPicker } from '../DateInputWithPicker'
import { Button } from '../button'
import { ButtonGroup } from '../button-group'
import { Delayed } from '../delayed'
import { Dialog, DialogBody, DialogDismiss, DialogFooter, DialogHeader } from '../dialog'
import { Input } from '../input'
import { Spinner } from '../spinner'
import {
  CustomDateRangePrefixEnum,
  getCustomStartEndDateRangeUrlParam,
  getParsedDateRange,
  getPrevPeriodDateRangeUrlParam,
  parseCustomDateRangeUrlParam,
} from './custom-date-range-utils'

const DATA_YEAR_START = 1980
const DATE_PICKER_MIN_DATE = new Date(`01/01/${DATA_YEAR_START}`)
const YEARS_MAX_VALUE = new Date().getFullYear() - DATA_YEAR_START

const TAB_BUTTON_COMMON_PROPS = { type: 'button', className: 'flex-1', contentClass: 'text-center' } as const

export enum CustomRangeType {
  StartEnd,
  Manual,
}

interface StartEndRange {
  kind: CustomRangeType.StartEnd
  start: string
  end: string
}

interface ManualRange {
  kind: CustomRangeType.Manual
  days?: number
  months?: number
  years?: number
}

export type CustomDateRangeType = StartEndRange | ManualRange

interface DialogContentProps {
  /**
   * Dialog state
   */
  dialogState: any
  dateRange?: string
  anchorDate: Date
  onDialogSubmit: (args: { dateRange: string | null }) => void
  isFetching?: boolean
}

const DEFAULT_MANUAL_RANGE: ManualRange = {
  kind: CustomRangeType.Manual,
  days: 0,
  months: 0,
  years: 0,
}

const DEFAULT_START_END_RANGE: StartEndRange = {
  kind: CustomRangeType.StartEnd,
  start: '',
  end: '',
}

const DATE_FORMAT_VIEW = 'MM/dd/yyyy'
const DATE_FORMAT_VIEW_PLACEHOLDER = DATE_FORMAT_VIEW.toUpperCase()

function isValidManualRangeState({ years = 0, months = 0, days = 0 }: ManualRange) {
  return years + months + days > 0
}

function isValidStartEndRangeState({ start, end }: StartEndRange) {
  return !!(start || end)
}

function getDefaultSelectedTab(dateRange?: string) {
  if (dateRange) {
    const [dateRangePrefix] = getParsedDateRange(dateRange)
    if (dateRangePrefix === CustomDateRangePrefixEnum.Range) {
      return CustomRangeType.StartEnd
    }
    if (dateRangePrefix === CustomDateRangePrefixEnum.Prev) {
      return CustomRangeType.Manual
    }
  }
  return CustomRangeType.StartEnd
}

function getDefaultSelectedDate({
  dateRange,
  anchorDate,
}: {
  dateRange?: string
  anchorDate: Date
}): CustomDateRangeType {
  if (dateRange) {
    const [dateRangePrefix] = getParsedDateRange(dateRange)
    const { start, end } = parseCustomDateRangeUrlParam({ dateRange, anchorDate })
    if (dateRangePrefix === CustomDateRangePrefixEnum.Range) {
      return {
        kind: CustomRangeType.StartEnd,
        start: start ? dateFns.format(start, DATE_FORMAT_VIEW) : '',
        end: end ? dateFns.format(end, DATE_FORMAT_VIEW) : '',
      }
    }
    if (dateRangePrefix === CustomDateRangePrefixEnum.Prev && start && end) {
      const { years, months, days } = dateFns.intervalToDuration({
        start: dateFns.sub(start, { days: 1 }),
        end,
      })
      return { kind: CustomRangeType.Manual, years, months, days }
    }
  }

  return DEFAULT_START_END_RANGE
}

export function CustomDateRange({
  dialogState,
  dateRange,
  anchorDate,
  onDialogSubmit,
  isFetching,
}: DialogContentProps) {
  const [errors, setErrors] = React.useState({
    start: false,
    end: false,
  })
  const [selectedTab, setSelectedTab] = React.useState(getDefaultSelectedTab(dateRange))

  const [state, setState] = React.useState<CustomDateRangeType>(getDefaultSelectedDate({ dateRange, anchorDate }))

  const { current: onChange } = React.useRef((kind: CustomRangeType, newValue: Partial<CustomDateRangeType>) => {
    setState((prevState) => {
      if (kind === CustomRangeType.Manual) {
        const defaults = prevState?.kind === CustomRangeType.Manual ? prevState : DEFAULT_MANUAL_RANGE
        return { ...defaults, ...newValue } as ManualRange
      }
      if (kind === CustomRangeType.StartEnd) {
        const defaults = prevState?.kind === CustomRangeType.StartEnd ? prevState : DEFAULT_START_END_RANGE

        const newState = { ...defaults, ...newValue }
        const parsedStartDate = newState.start
          ? parseInputAsDate({ input: newState.start, format: DATE_FORMAT_VIEW })
          : null
        const parsedEndDate = newState.end ? parseInputAsDate({ input: newState.end, format: DATE_FORMAT_VIEW }) : null

        const isParsedStartDateValid = parsedStartDate && dateFns.isValid(parsedStartDate)
        const isParsedEndDateValid = parsedEndDate && dateFns.isValid(parsedEndDate)

        if (isParsedStartDateValid) {
          newState.start = dateFns.format(parsedStartDate, DATE_FORMAT_VIEW)
        }

        if (isParsedEndDateValid) {
          newState.end = dateFns.format(parsedEndDate, DATE_FORMAT_VIEW)
        }

        if (isParsedStartDateValid && isParsedEndDateValid && parsedStartDate.getTime() > parsedEndDate.getTime()) {
          const newEnd = newState.start
          newState.start = newState.end
          newState.end = newEnd
        }
        return newState
      }
      return prevState
    })
  })

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()

    let dateRange = null

    if (state?.kind === CustomRangeType.StartEnd && isValidStartEndRangeState(state)) {
      const parsedStartDate = state.start ? parseInputAsDate({ input: state.start, format: DATE_FORMAT_VIEW }) : null
      const parsedEndDate = state.end ? parseInputAsDate({ input: state.end, format: DATE_FORMAT_VIEW }) : null
      const hasStartError = !!(state.start && !dateFns.isValid(parsedStartDate))
      const hasEndError = !!(state.end && !dateFns.isValid(parsedEndDate))
      setErrors((prevErrors) => ({
        ...prevErrors,
        start: hasStartError,
        end: hasEndError,
      }))

      if (hasStartError || hasEndError) {
        return
      }

      dateRange = getCustomStartEndDateRangeUrlParam({ start: parsedStartDate, end: parsedEndDate })
    } else if (state?.kind === CustomRangeType.Manual && isValidManualRangeState(state)) {
      dateRange = getPrevPeriodDateRangeUrlParam({
        days: state.days ?? 0,
        months: state.months ?? 0,
        years: state.years ?? 0,
      })
    }

    onDialogSubmit({ dateRange })
  }

  const isAbsolute = state?.kind === CustomRangeType.StartEnd
  const fromDate = isAbsolute ? state.start : DEFAULT_START_END_RANGE.start
  const toDate = isAbsolute ? state.end : DEFAULT_START_END_RANGE.end

  const manualRange = {
    ...DEFAULT_MANUAL_RANGE,
    ...(state?.kind === CustomRangeType.Manual ? state : {}),
  }

  return (
    <Dialog className="w-110" aria-label="Custom Range" state={dialogState}>
      <DialogHeader className="text-sm">Custom Date Range</DialogHeader>
      <form onSubmit={handleSubmit}>
        <DialogBody className="flex flex-col space-y-2">
          <ButtonGroup hasDivider={false}>
            <Button
              {...TAB_BUTTON_COMMON_PROPS}
              data-testid="custom-date-range-tab-button-start-end"
              active={selectedTab === CustomRangeType.StartEnd}
              onClick={() => setSelectedTab(CustomRangeType.StartEnd)}
            >
              Start / End
            </Button>
            <Button
              {...TAB_BUTTON_COMMON_PROPS}
              data-testid="custom-date-range-tab-button-range"
              active={selectedTab === CustomRangeType.Manual}
              onClick={() => setSelectedTab(CustomRangeType.Manual)}
            >
              Range
            </Button>
          </ButtonGroup>

          {selectedTab === CustomRangeType.StartEnd ? (
            <div className="grid grow grid-cols-2 gap-2 pt-2">
              <DateInputWithPicker
                trigger={
                  <Input
                    data-testid="date-range-input-start"
                    label="Start"
                    placeholder={DATE_FORMAT_VIEW_PLACEHOLDER}
                    inputClass="text-left"
                    type="text"
                    value={fromDate}
                    error={errors.start}
                  />
                }
                inputValue={fromDate}
                minDate={DATE_PICKER_MIN_DATE}
                onChange={(start) => {
                  onChange(CustomRangeType.StartEnd, { start })
                }}
                onErrorChange={(hasError) => {
                  setErrors((prevErrors) => ({ ...prevErrors, start: hasError }))
                }}
                hasError={errors.start}
                viewDateFormat={DATE_FORMAT_VIEW}
                dataDateFormat={DATE_FORMAT_VIEW}
              />

              <DateInputWithPicker
                trigger={
                  <Input
                    data-testid="date-range-input-end"
                    label="End"
                    placeholder={DATE_FORMAT_VIEW_PLACEHOLDER}
                    inputClass="text-left"
                    type="text"
                    value={toDate}
                    error={errors.end}
                  />
                }
                inputValue={toDate}
                minDate={DATE_PICKER_MIN_DATE}
                onChange={(end) => {
                  onChange(CustomRangeType.StartEnd, { end })
                }}
                onErrorChange={(hasError) => {
                  setErrors((prevErrors) => ({ ...prevErrors, end: hasError }))
                }}
                hasError={errors.end}
                viewDateFormat={DATE_FORMAT_VIEW}
                dataDateFormat={DATE_FORMAT_VIEW}
              />
            </div>
          ) : (
            <div className="grid grow grid-cols-3 gap-2 pt-2">
              <Input
                label="Years"
                type="number"
                data-testid="custom-date-range-range-years"
                value={manualRange.years}
                min={0}
                max={YEARS_MAX_VALUE}
                onChange={(ev: React.FormEvent<HTMLInputElement>) =>
                  onChange(CustomRangeType.Manual, { years: Number.parseInt(ev.currentTarget.value) })
                }
              />
              <Input
                label="Months"
                type="number"
                data-testid="custom-date-range-range-months"
                value={manualRange.months}
                min={0}
                max={11}
                onChange={(ev: React.FormEvent<HTMLInputElement>) =>
                  onChange(CustomRangeType.Manual, { months: Number.parseInt(ev.currentTarget.value) })
                }
              />
              <Input
                label="Days"
                type="number"
                data-testid="custom-date-range-range-days"
                value={manualRange.days}
                min={0}
                max={30}
                onChange={(ev: React.FormEvent<HTMLInputElement>) =>
                  onChange(CustomRangeType.Manual, { days: Number.parseInt(ev.currentTarget.value) })
                }
              />
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <div className="grow">
            <Button
              type="button"
              disabled={isFetching}
              onClick={() =>
                setState(state.kind === CustomRangeType.Manual ? DEFAULT_MANUAL_RANGE : DEFAULT_START_END_RANGE)
              }
            >
              Reset
            </Button>
          </div>
          <Button type="button" disabled={isFetching} as={DialogDismiss}>
            Close
          </Button>
          <Button type="submit" data-testid="custom-date-range-submit" theme="blue" disabled={isFetching}>
            <div className="flex items-center space-x-1">
              {isFetching && (
                <Delayed>
                  <Spinner width={16} />
                </Delayed>
              )}{' '}
              <span>Confirm</span>
            </div>
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  )
}
