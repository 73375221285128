import React from 'react'
import { To, useNavigate } from 'react-router-dom'

import { TIMEFRAME } from '../../constants'
import { Instrument } from '../../types'
import { useDialogState } from '../dialog'
import { getTimeframeFromDateRange } from './custom-date-range-utils'

interface IPathForTimeframeDateRangeProps {
  timeframe: TIMEFRAME | 'intraday'
  dateRange?: string | null
  ticker?: string
}

interface IUseCustomDateRangeDialogSubmitProps {
  anchorDate?: Date
  instrument?: Instrument
  shouldReloadOnNavigate?: boolean
  pathGenerator?: ({ ticker, timeframe, dateRange }: IPathForTimeframeDateRangeProps) => {
    pathname: string
    search: string
  }
}

type HandleDateRangeSelectFn<WithHref = true> = (
  props: WithHref extends true
    ? { value: string; timeframe?: TIMEFRAME; href?: To }
    : { value: string; timeframe?: TIMEFRAME },
  callback?: ({ value, timeframe, href }: { value: string; timeframe: TIMEFRAME; href?: To }) => void
) => void

export function useCustomDateRangeDialog({
  anchorDate,
  instrument,
  pathGenerator,
  shouldReloadOnNavigate = false,
}: IUseCustomDateRangeDialogSubmitProps) {
  const navigate = useNavigate()
  const customDateRangeDialogState = useDialogState()

  const isPremium = FinvizSettings.hasUserPremium

  const handleDateRangeSelect = React.useCallback<HandleDateRangeSelectFn>(
    ({ value, timeframe, href }, callback) => {
      if (!timeframe) return

      if (!href && pathGenerator) {
        const path = pathGenerator({ timeframe, dateRange: value })
        if (shouldReloadOnNavigate) {
          window.location.href = `${path.pathname}?${path.search}`
        } else {
          navigate(path)
        }
      }

      callback?.({ value, timeframe, href })
    },
    [navigate, pathGenerator, shouldReloadOnNavigate]
  )

  const getDateRangeTimeframe = React.useCallback(
    (dateRange: string | null) => {
      if (!anchorDate || !instrument || !dateRange) return null

      return {
        value: dateRange,
        timeframe: getTimeframeFromDateRange({
          dateRange: dateRange!,
          anchorDate,
          isPremium,
          instrument,
        }),
      }
    },
    [anchorDate, instrument, isPremium]
  )

  const onDialogSubmit = React.useCallback<HandleDateRangeSelectFn<false>>(
    ({ value: dateRange, timeframe: newTimeframe }, callback) => {
      handleDateRangeSelect(
        {
          value: dateRange,
          timeframe: newTimeframe,
        },
        callback
      )
      customDateRangeDialogState.hide()
    },
    // We only want `customDateRangeDialogState.hide`, not the whole dialog
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customDateRangeDialogState.hide, handleDateRangeSelect]
  )

  return { onDialogSubmit, customDateRangeDialogState, handleDateRangeSelect, getDateRangeTimeframe }
}
