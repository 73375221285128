import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { notifyOnVisibilityChange } from '../../../app/shared/documentVisibility'
import { Tooltip, TooltipTrigger, useTooltipState } from '../tooltip'

export function SelectTooltip({ content, children }: { content: React.ReactNode; children: JSX.Element }) {
  const tooltipState = useTooltipState({ placement: 'bottom', showTimeout: 300 })
  const context = Ariakit.useSelectContext()
  const isSelectOpen = context?.useState('open')
  const hasShownTooltip = React.useRef(false)

  React.useEffect(() => {
    if (isSelectOpen) hasShownTooltip.current = true
  }, [isSelectOpen])

  /**
   * Ignore focus re-trigger (do not show tooltip) when page visibility/focus state changes
   *  - some browsers re-trigger focus on `activeElement` when document becomes visible
   */
  React.useEffect(() => {
    const subscription = notifyOnVisibilityChange((visible) => {
      if (!visible) tooltipState?.hide()
    })
    return () => {
      subscription.unsubscribe()
    }
    // Changing this to `tooltip` dependency will run this effect on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TooltipTrigger
        focusable
        state={tooltipState}
        render={children}
        onFocusVisible={(ev) => {
          // Do not open again unless user focuses the button again (or presses another ESC)
          if (hasShownTooltip.current) ev.preventDefault()
          hasShownTooltip.current = false
        }}
      />
      <Tooltip state={tooltipState}>{content}</Tooltip>
    </>
  )
}

export function SelectTooltipWrapper({ content, children }: { content?: React.ReactNode; children: JSX.Element }) {
  if (!content) return null

  return <SelectTooltip content={content}>{children}</SelectTooltip>
}
