import classnames from 'classnames'
import * as React from 'react'

import { Icon } from './icon'

interface RedGreenTextProps {
  value?: number | null
  children: any
  rightContent?: React.ReactNode
  className?: string
  rightClass?: string
}

/**
 * Component which renders red/green text based on whether the value is positive or negative if value
 * is 0, it will not be colored and it will not display the guide triangle
 */
export function RedGreenText({ value, children, className, rightContent, rightClass }: RedGreenTextProps) {
  const hasValue = value && Number.isFinite(value)
  const showArrow = rightContent === undefined && hasValue && value !== 0

  return (
    <span
      className={classnames(className, 'w-full items-baseline justify-end whitespace-nowrap', {
        'text-positive': hasValue && value > 0,
        'text-negative': hasValue && value < 0,
        'text-muted-2': typeof hasValue !== 'number',
      })}
    >
      {children}
      <span className={classnames(rightClass, 'w-0 text-center', { 'pl-px': rightContent || showArrow })}>
        {showArrow ? (
          <Icon width={12} name={hasValue && value > 0 ? 'arrowUpShort' : 'arrowDownShort'} className="block" />
        ) : null}
        {rightContent}
      </span>
    </span>
  )
}

/**
 * Used to position multiple lines of RedGreen text, this component will make sure all numbers are
 * in line with their `rightContent`
 */
export function RedGreenMultiline({ className, children, ...props }: React.PropsWithChildren<any>) {
  return (
    <div {...props} className={classnames(className, 'table w-full')}>
      {React.Children.map(children, (child: React.ReactElement<RedGreenTextProps>) => {
        if (React.isValidElement(child) && child.type === RedGreenText) {
          return React.cloneElement(child, {
            className: classnames(child.props.className, 'table-row'),
            rightClass: 'table-cell align-middle',
          })
        }

        return child
      })}
    </div>
  )
}
