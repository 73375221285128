import * as Ariakit from '@ariakit/react'
import classNames from 'classnames'
import * as React from 'react'

interface ItemsGroupProps extends Omit<React.HTMLProps<HTMLDivElement>, 'ref' | 'label'> {
  /**
   * Group label
   */
  label?: React.ReactNode

  /**
   * Virtualized disables margins and paddings so the group could be positioned absolutely within a virtualized list
   */
  virtualized?: boolean

  /**
   * Toggles the border between groups
   *
   * @default true
   */
  hasBorder?: boolean
}

export const MenuGroup = React.forwardRef(function ItemsGroup(
  { label, virtualized, hasBorder = true, children, ...props }: React.PropsWithChildren<ItemsGroupProps>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Ariakit.MenuGroup
      {...props}
      ref={ref}
      className={classNames(props.className, 'flex w-full flex-col first:border-t-0', {
        'mt-1 pt-1 first:mt-0 first:pt-0': !virtualized,
        'border-t border-gray-100 dark:border-gray-600': hasBorder,
      })}
    >
      {label && <Ariakit.MenuGroupLabel className="px-2 text-2xs text-gray-500">{label}</Ariakit.MenuGroupLabel>}
      {children}
    </Ariakit.MenuGroup>
  )
})
