import { MapSubtype, MapSubtypeId, MapType, MapTypeId, PerfData, ScaleId } from '../types'

export const EXPANDED_MAP_BODY_CLASSS = 'is-map-expanded'
export const FALLBACK_PERF_RESPONSE: PerfData = { nodes: {}, additional: {}, subtype: MapSubtypeId.DayPerf, hash: '' }

type SubtypesRecord = Record<string, MapSubtype>
type SubtypesGroup = { items: MapSubtype[] }

const MAP_SUBTYPES_INTRADAY_BY_ID: SubtypesRecord = {
  [MapSubtypeId.IntradayPerf1m]: {
    value: MapSubtypeId.IntradayPerf1m,
    label: '1-Minute Performance',
    scaleId: ScaleId.IntradayReduced,
    tooltip:
      'Color indicates 1 minute stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf2m]: {
    value: MapSubtypeId.IntradayPerf2m,
    label: '2-Minute Performance',
    scaleId: ScaleId.IntradayReduced,
    tooltip:
      'Color indicates 2 minute stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf3m]: {
    value: MapSubtypeId.IntradayPerf3m,
    label: '3-Minute Performance',
    scaleId: ScaleId.IntradayReduced,
    tooltip:
      'Color indicates 3 minute stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf5m]: {
    value: MapSubtypeId.IntradayPerf5m,
    label: '5-Minute Performance',
    scaleId: ScaleId.IntradayReduced,
    tooltip:
      'Color indicates 5 minute stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf10m]: {
    value: MapSubtypeId.IntradayPerf10m,
    label: '10-Minute Performance',
    scaleId: ScaleId.IntradayReduced,
    tooltip:
      'Color indicates 10 minute stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf15m]: {
    value: MapSubtypeId.IntradayPerf15m,
    label: '15-Minute Performance',
    scaleId: ScaleId.IntradayReduced,
    tooltip:
      'Color indicates 15 minute stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf30m]: {
    value: MapSubtypeId.IntradayPerf30m,
    label: '30-Minute Performance',
    scaleId: ScaleId.DayPerf,
    tooltip:
      'Color indicates 30 minute stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf1h]: {
    value: MapSubtypeId.IntradayPerf1h,
    label: '1-Hour Performance',
    scaleId: ScaleId.DayPerf,
    tooltip:
      'Color indicates 1 hour stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf2h]: {
    value: MapSubtypeId.IntradayPerf2h,
    label: '2-Hour Performance',
    scaleId: ScaleId.DayPerf,
    tooltip:
      'Color indicates 2 hour stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerf4h]: {
    value: MapSubtypeId.IntradayPerf4h,
    label: '4-Hour Performance',
    scaleId: ScaleId.DayPerf,
    tooltip:
      'Color indicates 4 hour stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.IntradayPerfAH]: {
    value: MapSubtypeId.IntradayPerfAH,
    label: 'After-Hours Performance',
    scaleId: ScaleId.DayPerf,
    tooltip:
      'Color indicates after-hours stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
}

const MAP_SUBTYPES_INTERDAY_BY_ID: SubtypesRecord = {
  [MapSubtypeId.DayPerf]: {
    value: MapSubtypeId.DayPerf,
    label: '1-Day Performance',
    scaleId: ScaleId.DayPerf,
    tooltip:
      "Color indicates today's stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.WeekPerf]: {
    value: MapSubtypeId.WeekPerf,
    label: '1-Week Performance',
    scaleId: ScaleId.WeekPerf,
    tooltip:
      'Color indicates weekly stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.MonthPerf]: {
    value: MapSubtypeId.MonthPerf,
    label: '1-Month Performance',
    scaleId: ScaleId.MonthPerf,
    tooltip:
      'Color indicates monthly stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.ThreeMonthPerf]: {
    value: MapSubtypeId.ThreeMonthPerf,
    label: '3-Month Performance',
    scaleId: ScaleId.ThreeMonthPerf,
    tooltip:
      'Color indicates quarterly stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.SixMonthPerf]: {
    value: MapSubtypeId.SixMonthPerf,
    label: '6-Month Performance',
    scaleId: ScaleId.SixMonthPerf,
    tooltip:
      'Color indicates half-yearly stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.YearPerf]: {
    value: MapSubtypeId.YearPerf,
    label: '1-Year Performance',
    scaleId: ScaleId.YearPerf,
    tooltip:
      'Color indicates yearly stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.YearToDatePerf]: {
    value: MapSubtypeId.YearToDatePerf,
    label: 'Year To Date Performance',
    scaleId: ScaleId.YearPerf,
    tooltip:
      'Color indicates year to date stock performance in percent. Based on the color you can identify losers (red), neutral (black), and gainers (green).',
    countIndustryPerf: true,
    countSectorPerf: true,
  },
}

export const MAP_SUBTYPES_GENERAL: SubtypesRecord = {
  [MapSubtypeId.RelativeVolume]: {
    value: MapSubtypeId.RelativeVolume,
    label: 'Relative Volume',
    scaleId: ScaleId.RelativeVolume,
    tooltip:
      "Color indicates the stock's relative volume, ratio between today's and average volume. Values above 1.5 show strong volume, values below 0.5 indicate weak volume.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.PriceToEarnings]: {
    value: MapSubtypeId.PriceToEarnings,
    label: 'P/E',
    scaleId: ScaleId.PriceToEarnings,
    tooltip: "Color indicates the stock's price-to-earnings ratio.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.ForwardPriceToEearnings]: {
    value: MapSubtypeId.ForwardPriceToEearnings,
    label: 'Forward P/E',
    scaleId: ScaleId.ForwardPriceToEearnings,
    tooltip: "Color indicates the stock's forward price-to-earnings ratio.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.PriceEarningsToGrowth]: {
    value: MapSubtypeId.PriceEarningsToGrowth,
    label: 'PEG',
    scaleId: ScaleId.PriceEarningsToGrowth,
    tooltip: "Color indicates the stock's price-to-earnings-to-growth ratio.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.PriceToSellsRatio]: {
    value: MapSubtypeId.PriceToSellsRatio,
    label: 'P/S',
    scaleId: ScaleId.PriceToSellsRatio,
    tooltip: "Color indicates the stock's price-to-sales ratio.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.PriceToBookRatio]: {
    value: MapSubtypeId.PriceToBookRatio,
    label: 'P/B',
    scaleId: ScaleId.PriceToBookRatio,
    tooltip: "Color indicates the stock's price-to-book ratio.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.DividendYield]: {
    value: MapSubtypeId.DividendYield,
    label: 'Dividend Yield',
    scaleId: ScaleId.DividendYield,
    tooltip: "Color indicates the stock's dividend yield.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.EPSGrowth5y]: {
    value: MapSubtypeId.EPSGrowth5y,
    label: 'EPS Growth Past 5 Years',
    scaleId: ScaleId.EPSGrowth5y,
    tooltip: "Color indicates the stock's annual earnings (EPS) growth over past 5 years.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.FloatShort]: {
    value: MapSubtypeId.FloatShort,
    label: 'Float Short',
    scaleId: ScaleId.FloatShort,
    tooltip: "Color indicates percent of the stock's float sold short.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.AnalystRecommendation]: {
    value: MapSubtypeId.AnalystRecommendation,
    label: 'Analysts Recom.',
    scaleId: ScaleId.AnalystRecommendation,
    tooltip:
      "Color indicates the analyst's recommendations for given stocks. The rating is based on a 1-5 scale, ranging from a strong-buy, to a strong-sell.",
    countIndustryPerf: true,
    countSectorPerf: true,
  },
  [MapSubtypeId.EarningsPerf]: {
    value: MapSubtypeId.EarningsPerf,
    label: 'Earnings Day Performance',
    scaleId: ScaleId.MonthPerf,
    tooltip:
      "Color indicates stock's performance on earnings day. Next day performance for earnings reported after market close.",
    countIndustryPerf: false,
    countSectorPerf: false,
  },
  [MapSubtypeId.EarningsDate]: {
    value: MapSubtypeId.EarningsDate,
    label: 'Earnings Date',
    scaleId: ScaleId.EarningsDate,
    tooltip: "Color indicates stock's earnings date.",
    countIndustryPerf: false,
    countSectorPerf: false,
  },
}

export const MAP_SUBTYPE_DAY_PERF = MAP_SUBTYPES_INTERDAY_BY_ID[MapSubtypeId.DayPerf]

const MAP_SUBTYPES_INTRADAY: SubtypesGroup = { items: Object.values(MAP_SUBTYPES_INTRADAY_BY_ID) }
const MAP_SUBTYPES_INTERDAY: SubtypesGroup = { items: Object.values(MAP_SUBTYPES_INTERDAY_BY_ID) }
const MAP_SUBTYPES_TIMEFRAMES: SubtypesGroup[] = FinvizSettings.hasUserPremium
  ? [MAP_SUBTYPES_INTRADAY, MAP_SUBTYPES_INTERDAY]
  : [
      {
        items: [
          {
            label: 'Intraday',
            // @ts-expect-error - This isn’t a real selectable option, it’ll redirect users on click
            href: '/elite.ashx?utm_source=finviz&utm_medium=banner&utm_campaign=maps_intraday',
            reloadDocument: true,
          },
        ],
      },
      MAP_SUBTYPES_INTERDAY,
    ]

export const MAP_SUBTYPES_COMPANIES: SubtypesGroup[] = [
  ...MAP_SUBTYPES_TIMEFRAMES,
  { items: Object.values(MAP_SUBTYPES_GENERAL) },
]

export const MAP_SUBTYPES_ETF: SubtypesGroup[] = [
  ...MAP_SUBTYPES_TIMEFRAMES,
  { items: [MAP_SUBTYPES_GENERAL[MapSubtypeId.RelativeVolume]] },
]

export const MAP_TYPES: MapType[] = [
  {
    name: 'S&P 500',
    pageTitle: 'S&P 500 Map',
    description:
      "Standard and Poor's 500 index stocks categorized by sectors and industries. Size represents market cap.",
    type: MapTypeId.Sector,
    subtypes: MAP_SUBTYPES_COMPANIES,
  },
  {
    name: 'World',
    pageTitle: 'World Map',
    description:
      'Foreign ADR stocks listed on NYSE, NASDAQ, AMEX categorized by geo location. Size represents market cap.',
    type: MapTypeId.World,
    subtypes: MAP_SUBTYPES_COMPANIES,
  },
  {
    name: 'Full',
    pageTitle: 'Stock Market Map',
    description:
      'All stocks listed on US stock exchanges, categorized by sectors and industries. Size represents market cap.',
    type: MapTypeId.SectorFull,
    subtypes: MAP_SUBTYPES_COMPANIES,
  },
  {
    name: 'Exchange Traded Funds',
    pageTitle: 'ETF Map - Exchange Traded Funds Map',
    nameShort: 'ETF',
    description: 'ETFs categorized by sectors and styles. Size represents 3-month average dollar volume, $1M minimum.',
    type: MapTypeId.ETF,
    subtypes: MAP_SUBTYPES_ETF,
  },
]

export const MAP_TYPE_SCREENER = {
  name: 'Screener',
  pageTitle: 'Screener Map',
  description:
    'All stocks listed on US stock exchanges, categorized by sectors and industries. Stock size represents market cap.',
  type: MapTypeId.Screener,
  subtypes: MAP_SUBTYPES_COMPANIES,
}

export const MAP_TYPE_ETF_HOLDINGS_WIDGET = {
  name: 'ETF Holdings',
  pageTitle: '',
  description: '',
  type: MapTypeId.ETFHoldings,
  subtypes: MAP_SUBTYPES_COMPANIES,
}
